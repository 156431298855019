/* eslint-disable */

import React from 'react';
import './isLoadingModal.css';

const IsLoadingModal = ({message} : {message: string}) => {
  return (
    <div
      className={
        'fixed ' +
        'inset-0 ' +
        'bg-gray-600 ' +
        'bg-opacity-50 ' +
        'overflow-y-auto ' +
        'flex ' +
        'z-30 ' +
        'items-center ' +
        'justify-center ' +
        'h-full ' +
        'w-full'}
    >
      <div className={
        'p-5 border ' +
        'w-96 shadow-lg ' +
        'rounded-md ' +
        'bg-white'}
      >
        <div className="mt-3 text-center">
          <div className={
            'mx-auto ' +
            'flex ' +
            'items-center ' +
            'justify-center ' +
            'h-24 w-24 ' +
            'rounded-full'}
          >
            <div className="loader"></div>
          </div>
          {message}
        </div>
      </div>
    </div>
  );
};

export default IsLoadingModal;

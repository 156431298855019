/* eslint-disable */

const handleFileChosen = async (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsText(file);
  });
};

export const readAllFiles = async (AllFiles: File[]) => {
  const results = await Promise.all(AllFiles.map(async (file: File) => {
    const fileContents: any = await handleFileChosen(file);
    const jsonData = JSON.parse(fileContents);
    return jsonData;
  }));

  return results;
};

/* eslint-disable */
import { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "reactjs-dropdown-component";
import { activitiesState, langState } from "../state/activities";
import { useRecoilValue } from "recoil";
import { CSVLink } from "react-csv";
import { default as text } from "../translate/lang.json";
import { taxonomyObjects } from "../utils/taxonomy";
import { enCategory } from "../utils/categorylang";

import {
  calcTotal,
  calcTotalPerc,
  calcTotalTime,
  calcTimePercentage,
  calcTimeMin,
  calcTimeAvarage,
  calcNumberOfDays,
  calcDaysPercentage,
  calcTotalTimeCategory,
  calTotalNumberOfActivites,
} from "../utils/statisticsCounter";

type FilterProps = {
  label: string;
  value: string;
};

const PageStatistics = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!activities) {
      navigate("/");
    }
  }, []);

  const activities = useRecoilValue(activitiesState);
  const act: Array<any> = activities as Array<any>;
  const lang = useRecoilValue(langState);
  const [currentActivities, setCurrentActivities] = useState(
    filterActByProperty(act, "Inget Loggat")
  );
  const [dataArr, setdataArr] = useState(createTableArray(currentActivities));
  const [sumArr, setsumArr] = useState(createSumArray(currentActivities));
  const sortCategory = (event: FilterProps) => {
    let e: FilterProps = event;
    let data = createTableArray(currentActivities);
    if (e.value === "Alla kategorier") {
      setdataArr(data);
    } else {
      setdataArr(updateTableArray(data, e.value));
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setMessage(event.target.value);
    console.log(event);
  };

  function filterActByProperty(arr: Array<any>, value) {
    var filtered: Array<any> = [];
    for (let i = 0; i < arr.length; i++) {
      var filteredAct: Array<any> = [];

      for (let y = 0; y < arr[i].activities.length; y++) {
        if (arr[i].activities[y].category !== value) {
          filteredAct.push(arr[i].activities[y]);
        }
      }
      filtered.push({
        activities: filteredAct,
      });
    }
    return filtered;
  }
  let patterns: Array<any> = [];
  for (let i = 0; i < act.length; i++) {
    patterns.push({
      label: act[i].name + act[i].date,
      value: i,
    });
  }

  const kategorier = [
    {
      label: text.allCat[lang],
      value: text.allCat["SV"],
    },
    {
      label: text.selfcare[lang],
      value: text.selfcare["SV"],
    },
    {
      label: text.nursingcare[lang],
      value: text.nursingcare["SV"],
    },
    {
      label: text.homecare[lang],
      value: text.homecare["SV"],
    },
    {
      label: text.relaxation[lang],
      value: text.relaxation["SV"],
    },
    {
      label: text.movment[lang],
      value: text.movment["SV"],
    },
    {
      label: text.food[lang],
      value: text.food["SV"],
    },
    {
      label: text.work[lang],
      value: text.work["SV"],
    },
  ];
  const summaryCols = [
    {
      name: text.selfcare[lang] + " " + text.time[lang] + " %",
      selector: "Egenomsorg" as any,
      sortable: true,
    },
    {
      name: text.nursingcare[lang] + " " + text.time[lang] + " %",
      selector: "Omvardnad" as any,
      sortable: true,
    },
    {
      name: text.homecare[lang] + " " + text.time[lang] + " %",
      selector: "Hemvard" as any,
      sortable: true,
    },
    {
      name: text.relaxation[lang] + " " + text.time[lang] + " %",
      selector: "Avkoppling" as any,
      sortable: true,
    },
    {
      name: text.movment[lang] + " " + text.time[lang] + " %",
      selector: "Forflyttning" as any,
      sortable: true,
    },
    {
      name: text.food[lang] + " " + text.time[lang] + " %",
      selector: "Fixamat" as any,
      sortable: true,
    },
    {
      name: text.work[lang] + " " + text.time[lang] + " %",
      selector: "Arbeteskola" as any,
      sortable: true,
    },
    {
      name: text.totalact[lang],
      selector: "TotalTimeAct" as any,
      sortable: true,
    },
  ];
  const columns = [
    {
      name: text.activity[lang],
      selector: "activity" as any,
      sortable: true,
    },
    {
      name: text.code[lang],
      selector: "taxonomy" as any,
      sortable: true,
    },
    {
      name: text.category[lang],
      selector: "category" as any,
      sortable: true,
    },
    {
      name: text.frequency[lang],
      selector: "numbeOfTimes" as any,
      sortable: true,
    },
    {
      name: text.frequencyPerc[lang],
      selector: "numbeOfPercentage" as any,
      sortable: true,
    },
    {
      name: text.totalTimeMin[lang],
      selector: "totalTime" as any,
      sortable: true,
    },
    {
      name: text.totalTimePerc[lang],
      selector: "totalTimePercentage" as any,
      sortable: true,
    },
    {
      name: text.minTime[lang],
      selector: "minTime" as any,
      sortable: true,
    },
    {
      name: text.avgTime[lang],
      selector: "avgTime" as any,
      sortable: true,
    },
    {
      name: text.numberOfDays[lang],
      selector: "totalDate" as any,
      sortable: true,
    },
    {
      name: text.numberOfDaysPerc[lang],
      selector: "datePercentage" as any,
      sortable: true,
    },
  ];
  function clearEmptyLogs(arr: Array<any>) {
    let tempArr: Array<any> = [];
    for (let i = 0; i < arr.length; i++) {
      tempArr.push([i]);
      for (let y = 0; y < arr[i].activities.length; y++) {
        if (arr[i].activities[y].activity !== "Inget Loggat") {
          //arr[i].splice(y, 1);
        }
      }
    }
    return arr;
  }

  function updateTableArray(arr: Array<any>, category: String) {
    let tempArr: Array<any> = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].category === category || arr[i].category === enCategory(category)) {
        tempArr.push(arr[i]);
      }
    }
    return tempArr;
  }
  function createSumArray(arr: Array<any>) {
    arr = clearEmptyLogs(arr);
    let tempArr: Array<any> = [];
    tempArr.push({
      Egenomsorg: calcTotalTimeCategory(arr, "Egenomsorg"),
      Omvardnad: calcTotalTimeCategory(arr, "Omvårdnad"),
      Forflyttning: calcTotalTimeCategory(arr, "Förflyttning"),
      Arbeteskola: calcTotalTimeCategory(arr, "Arbete/skola"),
      Avkoppling: calcTotalTimeCategory(arr, "Avkoppling"),
      Fixamat: calcTotalTimeCategory(arr, "Fixa mat"),
      Hemvard: calcTotalTimeCategory(arr, "Hemvård"),
      TotalTimeAct: calTotalNumberOfActivites(arr),
    });
    return tempArr;
  }

  function createTableArray(arr: Array<any>) {
    let tempArr: Array<any> = [];
    let addedArr: Array<any> = [];
    for (let y = 0; y < arr.length; y++) {
      for (let i = 0; i < arr[y].activities.length; i++) {
        let act = arr[y].activities;
        if (arr[y].activities[i].activity !== "Inget Loggat") {
          if (!addedArr.includes(arr[y].activities[i].taxonomy)) {
            addedArr.push(arr[y].activities[i].taxonomy);
            var result = taxonomyObjects.find((obj) => {
              return obj.taxonomy === arr[y].activities[i].taxonomy;
            });
            tempArr.push({
              activity: lang === "EN" ? result?.name_EN : result?.name,
              taxonomy: arr[y].activities[i].taxonomy,
              category:
                lang === "EN"
                  ? enCategory(arr[y].activities[i].category)
                  : arr[y].activities[i].category,
              numbeOfTimes: calcTotal(arr, act[i].taxonomy),
              numbeOfPercentage: calcTotalPerc(arr, act[i].taxonomy),
              totalTime: calcTotalTime(arr, act[i].taxonomy),
              totalTimePercentage: calcTimePercentage(arr, act[i].taxonomy),
              minTime: calcTimeMin(arr, act[i].taxonomy),
              avgTime: calcTimeAvarage(arr, arr[y].activities[i].taxonomy),
              totalDate: calcNumberOfDays(arr, act[i].taxonomy),
              datePercentage: calcDaysPercentage(arr, act[i].taxonomy),
            });
          }
        }
      }
    }

    return tempArr;
  }

  return (
    <>
      <h1>{text.stat_title[lang]}</h1>
      <div className="w-full py-4 px-8 bg-white shadow-lg rounded-lg my-5">
        <h2 className="text-gray-800 text-1xl font-semibold">
          {text.stat_filter[lang]}:
        </h2>
        <div className="flex justify-start mt-4">
          <Dropdown
            name="location"
            title={text.stat_dates[lang]}
            list={patterns}
            onChange={(e: any) => {
              handleChange(e);
            }}
            styles={{
              headerTitle: { fontSize: 15 },
              wrapper: { width: 250, marginRight: 10 },
              listItem: { fontSize: 15, hoverBackgroundColor: "bg-main-blue" },
            }}
          />
          <Dropdown
            name="category"
            title={text.stat_cateogry[lang]}
            list={kategorier}
            onChange={(e: any) => {
              sortCategory(e);
            }}
            styles={{
              headerTitle: { fontSize: 15 },
              wrapper: { width: 220, marginRight: 10 },
              listItem: { fontSize: 15 },
            }}
          />
        </div>
        <div className="flex justify-end mt-4">
          <CSVLink
            data={dataArr}
            separator={";"}
            filename={"Vardagen_Statistik.csv"}
            className="btn btn-primary"
          >
            {text.csv[lang]}
          </CSVLink>
        </div>
      </div>
      <h2>{text.stat_list[lang]}</h2>
      <DataTable columns={columns} data={dataArr} pagination />
      <h2>{text.stat_summary[lang]}</h2>
      <DataTable columns={summaryCols} data={sumArr} pagination />
    </>
  );
};

export default PageStatistics;

/* eslint-disable */

import React, {useRef, useState} from 'react';

import {useSetRecoilState, useRecoilValue} from 'recoil';
import {setActivitiesState, langState} from '../state/activities';
import {readAllFiles} from '../utils/readAllFiles';
import {useNavigate} from 'react-router-dom';
import UIButton from '../components/UICommon/UIButton';
import {default as text} from '../translate/lang.json'

const pdfIcon = require('../assets/load-pdf.png');

const PageOverview = () => {
  const [files, setFiles] = useState<any>([]);
  const [fileName, setFileName] = useState('');
  const setActivities = useSetRecoilState(setActivitiesState);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const lang = useRecoilValue(langState);

  const loadFiles = (ref: React.RefObject<HTMLInputElement>) => {
    const filesArray = Array.from(ref.current!.files as ArrayLike<File>);
    //setFileName(filesArray[0].name);
    const files = readAllFiles(filesArray);
    files.then(function(result) {
      setFiles(result);
    }).catch(function() {
      alert('Error.');
    });
  };

  const handleClick = () => {
    setActivities(files);
    navigate('/aktiviteter/');
  };
  const navHelp = () => {
    navigate('/help/');
  };

  return (
    <>
    <div className="flex w-full h-full items-center justify-center">
      <div className={`flex flex-col items-center justify-center bg-white ` +
      `border-2 border-gray-accent rounded-lg w-3/4 h-4/5 ` +
      `mx-auto p-5`}>
        <h2 className="font-semibold text-black-accent text-3xl">
          {text.start_title[lang]}
        </h2>
        <span className={`text-sm text-black-accent text-center w-96 ` +
        `mt-5`}>
           {text.start_description[lang]}
        </span>
        <div className={`flex flex-col w-96 h-64 border-dashed border-2 ` +
        `items-center justify-center border-gray-light relative mt-6 mb-8 ` +
        `hover:border-main-blue transition-all ease-in`}>
          <img className="mb-5" src={pdfIcon} alt="UPLOAD" />
          <span className={`text-xs text-black-accent text-center w-96`}>
          {text.start_details[lang]}
          </span>
          
          <span className="text-xs text-black-accent my-3">{fileName}</span>
          <input
            className={`opacity-0 absolute top-0 left-0 bottom-0 right-0 ` +
            `cursor-pointer`}
            ref={inputRef}
            name="fileInput"
            type="file"
            accept='.json'
            onChange={() => loadFiles(inputRef)}
            multiple
          />
        </div>
        <UIButton
          text={text.start_button[lang]}
          handleClick={handleClick}
        />
      </div>
    </div>
    <div className="flex w-full h-full items-center justify-center mt-5">
      <div className={`flex flex-col items-center justify-center bg-white ` +
      `border-2 border-gray-accent rounded-lg w-3/4 h-4/5 ` +
      `mx-auto p-5`}>
    <p className="mb-5">
    {text.start_help_desc[lang]}
    </p>   
    <UIButton
          text={text.start_help[lang]}
          handleClick={navHelp}
        />
    </div>
    </div>
    </>
  );
};

export default PageOverview;

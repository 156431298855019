/* eslint-disable */

import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import menuItems, {menuItemsEN} from '../../config/sideBarMenu';
import logo from '../../assets/logo.svg';
import se from '../../assets/SE.svg'
import us from '../../assets/UK.svg'
import UIButton from '../UICommon/UIButton';
import UIButtonSmallRounded from '../UICommon/UIButtonSmallRounded';
import { setLangState, langState } from '../../state/activities';
import {useSetRecoilState, useRecoilValue } from 'recoil';

const activeClass = 'flex items-center text-gray-menu relative ' +
'uppercase text-white-menu bg-gray-active px-6 py-4 white-line ml-[0px] pl-[0px] text-[14px]';
const inactiveClass = 'flex items-center text-gray-menu px-6 py-4 ml-[0px] pl-[0px] text-[14px]';

const AppSideBarMenu = () => {
  const setLang = useSetRecoilState(setLangState)
  const lang = useRecoilValue(langState)
  return (
    <div
      className='flex flex-col justify-between h-full ` +
      `w-full text-gray-800'
    >
      <div>
        <div className="mb-12 flex justify-start px-6 py-8">
          <img src={logo} alt="LOGO" />
        </div>
        {lang === "SV" ? 
    menuItems.map((el) => {
      // Check if el.link is an external URL
      const isExternalLink = /^https?:\/\//.test(el.link);

      if (isExternalLink) {
        return (
          <a
            href={el.link}
            className={inactiveClass} // Handle classes for external links as needed
            key={el.id}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="mr-5">
              <img src={el.icon} alt="" />
            </span>
            {el.name}
          </a>
        );
      }

      return (
        <NavLink
          to={el.link}
          className={(navData) =>
            navData.isActive ? activeClass : inactiveClass
          }
          key={el.id}
          // eslint-disable-next-line react/no-children-prop
          children={(navData) => (
            <>
              <span className="mr-5">
                <img
                  src={navData.isActive ? el.iconActive : el.icon}
                  alt=""
                />
              </span>
              {el.name}
            </>
          )}
        />
      );
    })
    :
    menuItemsEN.map((el) => {
      // Check if el.link is an external URL
      const isExternalLink = /^https?:\/\//.test(el.link);

      if (isExternalLink) {
        return (
          <a
            href={el.link}
            className={inactiveClass} // Handle classes for external links as needed
            key={el.id}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="mr-5">
              <img src={el.icon} alt="" />
            </span>
            {el.name}
          </a>
        );
      }

      return (
        <NavLink
          to={el.link}
          className={(navData) =>
            navData.isActive ? activeClass : inactiveClass
          }
          key={el.id}
          // eslint-disable-next-line react/no-children-prop
          children={(navData) => (
            <>
              <span className="mr-5">
                <img
                  src={navData.isActive ? el.iconActive : el.icon}
                  alt=""
                />
              </span>
              {el.name}
            </>
          )}
        />
      );
    })
}
          <div className="mt-12 flex">
            <button><img  src={se} className={'w-[100px] h-[52%]'}  onClick={() => setLang('SV')}/></button>
            <button><img  src={us} className={'w-[100px] h-[52%]'}  onClick={() => setLang('EN')}/></button>
          </div>
         
      </div>
    </div>
  );
};

export default AppSideBarMenu;

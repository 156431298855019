/* eslint-disable */
import {FC} from 'react';
import {useRecoilValue} from 'recoil';
import {langState} from '../../state/activities';
import {default as text} from '../../translate/lang.json'
import { getMins, calcMins } from '../../utils/statisticsCounter';
import { tickArray, formatYAxis } from "../../utils/yAxisTimeStamps";
import { enTogetherness } from '../../utils/categorylang';
import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend,
  } from 'recharts';
interface Props {
  arr: any;
  showYAxis?: boolean;
}
const TogethernessGraph:FC <Props> = ({arr, showYAxis = true}) => {
  let newArr = arr.togetherness;
  let totalMins = 1440;
  const lang = useRecoilValue(langState);
  function createJson(arr: Array<any>, cat: string, startId: number) {
    let jsonObject = {};
    let total = arr.length;
    let currentMin = 0;
    for (let i = 0; i < arr.length; i++) {
      if (i + 1 < total) {
        currentMin = calcMins(arr[i].start, arr[i + 1].start);
      } else {
        currentMin = totalMins - getMins(arr[i].start);
      }
      Object.assign(jsonObject, {[i + startId]: currentMin});
    }
    return jsonObject;
  }
  function createStackedBar(arr: Array<any>, cat: Array<string>): Array<any> {
    let tmpArry: Array<any> = [];
    let length = 0;
    for (let i = 0; i < cat.length; i++) {
      let jsonObject = createJson(arr, cat[i], length);
      tmpArry.push({
        name: cat[i],
        name_EN: enTogetherness(cat[i]),
        ...jsonObject,
      });
      length += arr.length;
    }
    return tmpArry;
  }

  function createColorArray(arr: Array<any>, cat: Array<string>) {
    let colorArr:Array<boolean> = [];

    for (let i = 0; i < cat.length; i++) {
      for (let y = 0; y < arr.length; y++) {
        if (cat[i] === arr[y].with  || cat[i] === "Jag") {
          colorArr.push(true);
        } else {
          colorArr.push(false);
        }
      }
    }
    return colorArr;
  }
  let categories = [
    'Jag',
    'Med familj',
    'Med andra'
  ]

  let dataArr = createStackedBar(newArr, categories);
  let colors = createColorArray(newArr, categories);
    return(
       <>
       <h1 className='text-center'>{text.label_samvaro[lang]}</h1>
       <ResponsiveContainer
        height={550}
        width="100%"
        >
        <ComposedChart
        width={600}
        height={550}
        data={dataArr}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            angle={-20}
            dataKey={lang ==="EN"? 'name_EN' :'name'}
            interval={0}
            style={{
              borderTop: 15,
              borderColor: "black",
              fontSize: '0.7rem',
              fontWeight: 900,
            }}
          />
          {showYAxis && (
            <YAxis
              type="number"
              dataKey="x"
              ticks={tickArray}
              tickFormatter={formatYAxis}
              domain={[0, totalMins]}
              allowDataOverflow={true}
            />
          )}
         {colors.map((value, index) => (
            <Bar
              dataKey={index}
              stackId={'a'}
              barSize={10}
              fill={value ? '#000000' : 'transparent'}
              legendType='none'
            ></Bar>
          ))}
      </ComposedChart>
      </ResponsiveContainer>
      </>
    )
}

export default TogethernessGraph
export const tickArray = [
  0, 120, 240, 360, 480, 600, 720, 840, 960, 1080, 1200, 1320, 1440,
];

export const formatYAxis = (tickItem: string) => {
  let timeString = 'Kl ';
  const time = parseInt(tickItem);
  if (time / 60 < 10) {
    timeString += '0' + time / 60;
  } else {
    timeString += time / 60;
  }

  return timeString + ':00';
};

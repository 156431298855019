/* eslint-disable */

import React from 'react';
import { langState } from '../state/activities';
import { useRecoilValue } from 'recoil';
import { default as text } from '../translate/lang.json';

const PageAboutUs = () => {
  const lang = useRecoilValue(langState);
  return (
    <>
      <h1 className="font-semibold text-black-accent text-3xl pb-4">{text.about_us_title[lang]}</h1>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item bg-white border border-gray-200">
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div className="accordion-body py-4 px-5">
              {text.about_us_people[lang]}
              <br /><br />
              {text.about_us_questions[lang]}
              <br /><br />
              {text.about_us_idea[lang]}
              <br /><br />
              {text.about_us_book[lang]}
              <a className="text-blue-700" href="https://www.diva-portal.org/smash/get/diva2:1252285/FULLTEXT01.pdf." target="_blank">
                Diva-portal
              </a>
              <br /><br />
              <hr/>
              {text.about_us_vardagboken[lang]}
              <br /><br />
              <a className="text-blue-700" href="https://vardagboken.everydayanalytics.se" target="_blank">
                Vardagboken
              </a>
              <br /><br />
              {text.about_us_homescreen[lang]}
              <br /><br />
              <a className="text-blue-700" href="https://support.apple.com/sv-se/guide/iphone/iph42ab2f3a7/ios" target="_blank">
                Guide IOS Homescreen
              </a>
              <br /><br />
              <a className="text-blue-700" href="https://www.androidauthority.com/add-website-android-iphone-home-screen-3181682/#1" target="_blank">
                Guide Android Homescreen
              </a>
            </div>
          </div>
        </div>
      </div>
      <h1 className="font-semibold text-black-accent text-3xl pt-8 pb-2">{text.instructional_videos_title[lang]}</h1>
      <div className="grid grid-cols-1 2xl:grid-cols-2 pt-4">
        <iframe
          className="mr-10 mb-6 2xl:mb-0"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/YSywPz9FcKk?si=_bBr28i5uW_5Y5r1"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
        >
        </iframe>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/yIS30TOcIEw?si=Ib3qCZ53CPDzQxjn"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
        >
        </iframe>
      </div>
    </>
  );
};

export default PageAboutUs;

/* eslint-disable */

import React from 'react';
import {useRecoilValue} from 'recoil';
import {useNavigate} from 'react-router-dom';
import {ResponsiveContainer} from 'recharts';
import ActivityGraph from '../components/Graph/ActivityGraph';
import {activitiesState, langState} from '../state/activities';
import PlacesGraph from '../components/Graph/PlacesGraph';
import MoodGraph from '../components/Graph/MoodGraph';
import PainGraph from '../components/Graph/PainGraph';
import TogethernessGraph from '../components/Graph/TogethernessGraph';
import UIButtonSmallRounded from '../components/UICommon/UIButtonSmallRounded';
import {default as text} from '../translate/lang.json'
import {Rulers} from '../components/Rulers/rulers';
import { SmallerRulers } from '../components/Rulers/shorterRulers';

const PageActivities = () => {
  const navigate = useNavigate();
  const [showResults, setShowResults] = React.useState(false)
  const onClick = () => setShowResults(showResults ? false : true)
  const activities = useRecoilValue(activitiesState);
  const lang = useRecoilValue(langState);
  
  const act: Array<any> = activities as Array<any>;
  return (
    <>
      <h1 className="text-black-accent text-5xl font-abel mb-8">
        {text.header[lang]}
      </h1>
      <div className="flex mb-8">
        <UIButtonSmallRounded
          text={text.import_button[lang]}
          handleClick={() => navigate('/')}
        />
        <UIButtonSmallRounded
          text={text.stats_button[lang]}
          handleClick={() => navigate('/aktiviteter/statistik/')}
        />
        <UIButtonSmallRounded
          text={text.rulers_button[lang]}
          handleClick={() => onClick()}
        />
      </div>
      
      {act.map((value, index) => (
        <>
        <div className={`w-52 h-20 flex flex-col border-2 p-3 mb-8 ` +
        `border-gray-accent rounded-lg bg-white items-start`}>
          <div className="text-xl text-black font-bold">{value.name}</div>
          <div className="text-sm text-black">{value.date}</div>
        </div>
        <div
            className={`grid grid-cols-3 3xl:grid-cols-5 w-full relative justify-evenly`}
          >
            {showResults ? <Rulers /> : null}
            <div className={'relative'}>
              <ActivityGraph arr={value} />
            </div>
            <div className={'relative'}>
              <PlacesGraph arr={value} showYAxis />
            </div>
            <div className={'relative'}>
              <TogethernessGraph arr={value} showYAxis />
            </div>
            <div className={'relative'}>
              {showResults ? <SmallerRulers /> : null}
              <MoodGraph arr={value} showYAxis />
            </div>
            <div className={'relative'}>
              <PainGraph arr={value} showYAxis />
            </div>
          </div>
        </>
        ))}
    </>
  );
};

export default PageActivities;

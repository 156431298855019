export function enCategory(category: String) {
  if (category === 'Egenomsorg') {
    return 'Self care';
  }
  if (category === 'Omvårdnad') {
    return 'Nursing care';
  }
  if (category === 'Hemvård') {
    return 'Home care';
  }
  if (category === 'Avkoppling') {
    return 'Relaxation';
  }
  if (category === 'Förflyttning') {
    return 'Movment';
  }
  if (category === 'Fixa mat') {
    return 'Fix food';
  }
  if (category === 'Arbete/skola') {
    return 'Work/school';
  } else {
    return 'no category';
  }
}

export function enPlaces(category: String) {
  if (category === 'Hemma inomhus') {
    return 'Home inside';
  }
  if (category === 'Hemma utomhus') {
    return 'Home outside';
  }
  if (category === 'Arbete') {
    return 'Work';
  }
  if (category === 'Skola') {
    return 'School';
  }
  if (category === 'Annat') {
    return 'Other';
  } else {
    return 'no category';
  }
}

export function enTogetherness(category: String) {
  if (category === 'Jag') {
    return 'Myself';
  }
  if (category === 'Med familj') {
    return 'With family';
  }
  if (category === 'Med andra') {
    return 'With others';
  } else {
    return 'no category';
  }
}

export function enMood(category: String) {
  if (category === 'Neutral') {
    return 'Neutral';
  }
  if (category === 'Glad') {
    return 'Happy';
  }
  if (category === 'Koncentrerad') {
    return 'Concentrated';
  }
  if (category === 'Nedstämd') {
    return 'Depressed';
  }
  if (category === 'Pigg') {
    return 'Alert';
  }
  if (category === 'Stressad') {
    return 'Stressed';
  }
  if (category === 'Arg') {
    return 'Angry';
  }
  if (category === 'Trött') {
    return 'Tierd';
  }
  if (category === 'Sömnig') {
    return 'Sleepy';
  } else {
    return 'no category';
  }
}


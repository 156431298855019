/* eslint-disable */
import {FC} from 'react';
import {useRecoilValue} from 'recoil';
import {langState} from '../../state/activities';
import {default as text} from '../../translate/lang.json'
import { getMins, calcMins } from '../../utils/statisticsCounter';
import { tickArray, formatYAxis } from "../../utils/yAxisTimeStamps";
import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend,
  } from 'recharts';

interface Props {
  arr: any;
  showYAxis?: boolean;
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    let result = Object.keys(payload[0].payload).filter(v => v.startsWith("act"));
    result.reverse();
    return (
      <div className="custom-tooltip">
        {result.map((value, index) => (
          <p className="desc">{payload[0].payload[value]}</p>
         ))}
      </div>
    );
  }

  return null;
};
const PainGraph:FC <Props> = ({arr, showYAxis = true}) => {
  let totalMins = 1440;
  let painArr = arr.pain;
  const lang = useRecoilValue(langState)


  function createStackedBar(arr: Array<any>): Array<any>{
    let tmpArry: Array<any> = [];
    
    let total = arr.length;
    let currentMin = 0;
    let jsonObject = {};
    let pain =  "";
    for(let i=0; i<arr.length; i++){
      
      if(i + 1 < total){
        currentMin = calcMins(arr[i].start, arr[i + 1].start);
      }
      else{
        currentMin = totalMins - getMins(arr[i].start);
      }
      pain = arr[i].level
      let date = arr[i].start
      if(parseInt(pain) > 0){
        Object.assign(jsonObject, {["act" + i]: date + " - " + pain})
      }
      
      Object.assign(jsonObject, {[i]: currentMin})
    }
    tmpArry.push({
      name: "1-10",
      ...jsonObject
    })

    return tmpArry
  }
  function createColorArray(arr: Array<any>) {
    let colorArr:Array<boolean> = [];
    for(let i = 0; i< arr.length; i++){
      if(arr[i].level > 0){
        colorArr.push(true);
      }
      else{
        colorArr.push(false);
      }
    }
    return colorArr
  }
  
  let dataArr = createStackedBar(painArr);
  let colors = createColorArray(painArr);

    return(
       <>
       <h1 className='text-center mr-20'>{text.label_pain[lang]}</h1>
       <ResponsiveContainer
       height={550}
       width="100%"
       >
        <ComposedChart
       
        data={dataArr}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
          <XAxis
           angle={-20}
            interval={0}
            label={{value: '1-10', fill: '#FF0000', fontSize: "0.75rem", fontWeight: 900}}
            style={{
              borderTop: 15,
              borderColor: "black",
              fontSize: '0',
              fontWeight: 900,
            }}
          />
          {showYAxis && (
            <YAxis
              type="number"
              dataKey="x"
              ticks={tickArray}
              tickFormatter={formatYAxis}
              domain={[0, totalMins]}
              allowDataOverflow={true}
            />
          )}
          <Tooltip content={<CustomTooltip />} />
          {colors.map((value, index) => (
            <Bar
              dataKey={index}
              stackId={'a'}
              barSize={10}
              fill={value ? '#FF0000' : 'transparent'}
              legendType='none'
            ></Bar>
          ))}
      </ComposedChart>
      </ResponsiveContainer>
      </>
    )
}

export default PainGraph
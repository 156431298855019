/* eslint-disable */

import {atom, selector} from 'recoil';
import Activity from '../types/activities';

export const langState = atom({
    key: 'langState',
    default: 'SV' as string,
});

export const setLangState = selector({
  key: 'setLangState',
  get: ({get}) => get(langState),
  set: ({set}, newValue) => {
    set(langState, newValue as string);
  },
});


export const activitiesState = atom({
  key: 'activitiesState',
  default: [] as Activity[],
});

export const setActivitiesState = selector({
  key: 'setActivitiesState',
  get: ({get}) => get(activitiesState),
  set: ({set}, newValue) => {
    set(activitiesState, newValue as Activity[]);
  },
});

import React from 'react';
import Draggable from 'react-draggable';

export const SmallerRulers = () => (
  <>
    <Draggable bounds={{top: 2, left: 0, right: 0, bottom: 498}}>
      <div
        className="bg-main-blue absolute block 2xl:hidden"
        style={{
          width: '42vw',
          marginTop: 42,
          left: '5.5vw',
          height: 2,
          zIndex: 100,
          position: 'absolute',
        }}
      >
        <div
          className="bg-main-blue block 2xl:hidden"
          style={{
            height: 40,
            width: 40,
            marginTop: -20,
            borderRadius: '50%',
            marginLeft: '42vw',
          }}
        ></div>
      </div>
    </Draggable>
    <Draggable bounds={{top: 2, left: 0, right: 0, bottom: 498}}>
      <div
        className="bg-main-blue absolute block 2xl:hidden"
        style={{
          width: '42vw',
          left: '5.5vw',
          marginTop: 42,
          height: 2,
          zIndex: 100,
          position: 'absolute',
        }}
      >
        <div
          className="bg-main-blue block 2xl:hidden"
          style={{
            height: 40,
            width: 40,
            marginTop: -20,
            borderRadius: '50%',
            marginLeft: '42vw',
          }}
        ></div>
      </div>
    </Draggable>
  </>
);

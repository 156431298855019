/* eslint-disable */

import React from 'react';

const UIButton = ({
  text,
  handleClick,
}: {
  text: string,
  handleClick: () => void;
}) => {
  return (
    <button
      className="bg-main-blue text-white text-sm py-3 px-32 font-bold shadow-lg"
      onClick={() => handleClick()}
    >
      {text}
    </button>
  );
};

export default UIButton;

/* eslint-disable */

import React from 'react';

const UIButtonSmallRounded = ({
  text,
  handleClick,
}: {
  text: string,
  handleClick: () => void;
}) => {
  return (
    <button
      className={`bg-main-blue text-white text-sm py-2 px-8 font-bold ` +
      `shadow-lg rounded-md mr-5`}
      onClick={() => handleClick()}
    >
      {text}
    </button>
  );
};

export default UIButtonSmallRounded;

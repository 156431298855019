/* eslint-disable */
import {FC} from 'react';
import {useRecoilValue} from 'recoil';
import {langState} from '../../state/activities';
import {default as text} from '../../translate/lang.json'
import { enMood } from '../../utils/categorylang';

import { getMins, calcMins } from '../../utils/statisticsCounter';
import { tickArray, formatYAxis } from "../../utils/yAxisTimeStamps";
import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend,
  } from 'recharts';
  interface Props {
    arr: any;
    showYAxis?: boolean;
  }
  
const MoodGraph:FC <Props> = ({arr, showYAxis = true}) => {
  //const activities = useRecoilValue(activitiesState);
  //const act: Array<any> = activities as Array<any>;
  let newArr = arr.mood;
  let totalMins = 1440;
  const lang = useRecoilValue(langState)


  function createJson(arr: Array<any>, cat: string, startId: number) {
    let jsonObject = {};
    let total = arr.length;
    let currentMin = 0;
    for (let i = 0; i < arr.length; i++) {
      if (i + 1 < total) {
        currentMin = calcMins(arr[i].start, arr[i + 1].start);
      } else {
        currentMin = totalMins - getMins(arr[i].start);
      }
      Object.assign(jsonObject, {[i + startId]: currentMin});
    }
    return jsonObject;
  }
  function createStackedBar(arr: Array<any>, cat: Array<string>): Array<any> {
    let tmpArry: Array<any> = [];
    let length = 0;
    for (let i = 0; i < cat.length; i++) {
      let jsonObject = createJson(arr, cat[i], length);
      tmpArry.push({
        name: cat[i],
        name_EN: enMood(cat[i]),
        ...jsonObject,
      });
      length += arr.length;
    }
    return tmpArry;
  }

  function createColorArray(arr: Array<any>, cat: Array<string>) {
    let colorArr:Array<boolean> = [];

    for (let i = 0; i < cat.length; i++) {
      for (let y = 0; y < arr.length; y++) {
        if (cat[i] === arr[y].feeling) {
          colorArr.push(true);
        } else {
          colorArr.push(false);
        }
      }
    }
    return colorArr;
  }
  let categories = [
    'Neutral',
    'Glad',
    'Koncentrerad',
    'Pigg',
    'Nedstämd',
    'Stressad',
    'Arg',
    'Trött',
    'Sömnig'
  ]

  let dataArr = createStackedBar(newArr, categories);


  let colors = createColorArray(newArr, categories);
  
    let data=[
       {
           name: 'Neutral',
           1: 300,
        },
        {
            name: 'Glad',
            1: 300,  
         },
         {
            name: 'Nedstämd',
            1: 300,
         },
         {
            name: 'Koncentrerad',
            1: 300,
         },
         {
            name: 'Trött',
            1: 300,
         },
         {
          name: 'Sömning',
          1: 300,
       },
    ]
    return(
       <>
       <h1 className='text-center'>{text.label_mood[lang]}</h1>
       <ResponsiveContainer
        height={550}
        width="100%"
        >
        <ComposedChart
        
        data={dataArr}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey={lang ==="EN"? 'name_EN' :'name'}
            interval={0}
            angle={-45}
            style={{
              borderTop: 15,
              borderColor: "black",
              fontSize: '0.6rem',
              fontWeight: 900,
            }}
          />
          {showYAxis && (
            <YAxis
              type="number"
              dataKey="x"
              ticks={tickArray}
              tickFormatter={formatYAxis}
              domain={[0, totalMins]}
              allowDataOverflow={true}
            />
          )}
         {colors.map((value, index) => (
            <Bar
              dataKey={index}
              stackId={'a'}
              barSize={10}
              fill={value ? '#000000' : 'transparent'}
              legendType='none'
            ></Bar>
          ))}
      </ComposedChart>
      </ResponsiveContainer>
      </>
    )
}

export default MoodGraph
/* eslint-disable */
import {FC} from 'react';
import {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import { getMins, calcMins } from '../../utils/statisticsCounter';
import { tickArray, formatYAxis } from "../../utils/yAxisTimeStamps";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

import {langState} from '../../state/activities';
import IsLoadingModal from '../AppModals/IsLoadingModal';
import {default as text} from '../../translate/lang.json'
import {enCategory} from '../../utils/categorylang'
import { taxonomyObjects } from '../../utils/taxonomy';

interface Props {
  arr: any;
}
var tooltip

/* eslint-disable */
const ActivityGraph:FC <Props> = ({arr}) => {
  const [isLoading, setIsLoading] = useState(false);
  let newArr = arr.activities;
  let totalMins = 1440;
  const lang = useRecoilValue(langState)

  function calcNumberOfLines(arr: Array<any>){
    let numberOfLines:number = 0;
    let lineObjects:Array<any> = [];
    for(let i = 1; i < arr.length; i++){
      if(arr[i].category !== arr[i-1].category){
        numberOfLines++
        let mins = getMins(arr[i].start);
        lineObjects.push({
          val: mins,
          start: arr[i-1].category,
          end: arr[i].category
        })
      }
    }
    return lineObjects;
  }

  


  function createJson(arr: Array<any>, cat: string, startId: number, lineArr: Array<any>) {
    let jsonObject = {};
    let total = arr.length;
    let currentMin = 0;
    let act =  "";
    for (let i = 0; i < arr.length; i++) {
      if (i + 1 < total) {
        currentMin = calcMins(arr[i].start, arr[i + 1].start);
      } else {
        currentMin = totalMins - getMins(arr[i].start);
      }
      var result = taxonomyObjects.find(obj => {
        return obj.taxonomy === arr[i].taxonomy
      })
      act = lang === "EN" ? result?.name_EN : arr[i].activity
      let date = arr[i].start
      if(arr[i].category === cat){
        Object.assign(jsonObject, {["act" + i]: act + " " +date})
      }
      
      Object.assign(jsonObject, {[i + startId]: currentMin});
    }
    for(let y = 0; y < lineArr.length; y++){
      if(lineArr[y].start === cat || lineArr[y].end === cat){
        Object.assign(jsonObject, {['line_'+ (y)]: lineArr[y].val});
      }
    }
    return jsonObject;
  }
  function createStackedBar(arr: Array<any>, cat: Array<string>, lineArr: Array<any>): Array<any> {
    let tmpArry: Array<any> = [];
    let length = 0;
    for (let i = 0; i < cat.length; i++) {
      let jsonObject = createJson(arr, cat[i], length, lineArr);
      tmpArry.push({
        name: cat[i],
        name_EN: enCategory(cat[i]),
        ...jsonObject,
      });
      length += arr.length;
    }
    return tmpArry;
  }
  function createColorArray(arr: Array<any>, cat: Array<string>) {
    let colorArr:Array<boolean> = [];
    for (let i = 0; i < cat.length; i++) {
      for (let y = 0; y < arr.length; y++) {
        if (cat[i] === arr[y].category) {
          colorArr.push(true);
        } else {
          colorArr.push(false);
        }
      }
    }
    return colorArr;
  }
  function createToolArray(arr: Array <any>, cat: Array <any>){
    let tooltip:Array<string> = [];
    for (let i = 0; i < cat.length; i++) {
      for (let y = 0; y < arr.length; y++) {
        if (cat[i] === arr[y].category) {
          tooltip.push(arr[y].activity);
        } else {
          tooltip.push(" ");
        }
      }
    }
    return tooltip;
  }

  let activitesList = [
    'Egenomsorg',
    'Omvårdnad',
    'Hemvård',
    'Avkoppling',
    'Förflyttning',
    'Fixa mat',
    'Arbete/skola',
  ];

  let lineArr = calcNumberOfLines(newArr);
  let dataArr = createStackedBar(newArr, activitesList, lineArr);
  let colors = createColorArray(newArr, activitesList);
  let tooltips = createToolArray(newArr, activitesList);
  
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length && tooltip) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          <p className="label">{`${tooltips[tooltip.index]}`}</p>
        </div>
      );
    }
    return null;
  };
  
  function lineString(index:number){

    return "line_"+index;
  }

  useEffect(() => {
    setIsLoading(true);
  });

  const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;
  
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={3} fill="black" viewBox="0 0 1024 1024">
        
      </svg>
    );
  };

  if (!isLoading) {
    return <IsLoadingModal message="Läser in aktiviteter..."/>
  }

  
  return (    
  <>
      <h1 className='text-center'>{text.label_activities[lang]}</h1>
       <ResponsiveContainer
        height={550}
        width="100%"
        >

        <ComposedChart
          
          
          data={dataArr}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey={lang==="EN" ? "name_EN" : 'name'}
            interval={0}
            angle={-20}
            style={{
              borderTop: 15,
              borderColor: "black",
              fontSize: '0.6rem',
              fontWeight: 900,
            }}
          />
          <YAxis
            type='number'
            dataKey='x'
            ticks={tickArray}
            tickFormatter={formatYAxis}
            domain={[0, totalMins]}
            allowDataOverflow={true}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {colors.map((value, index) => (
            <Bar
              dataKey={index}
              stackId={'a'}
              barSize={10}
              fill={value ? '#000000' : 'transparent'}
              legendType='none'
              onMouseOver={ () => tooltip={index}}
            ></Bar>
          ))}
          {lineArr.map((val, index) => (
            <Line
              dataKey={lineString(index)}
              stroke="#000000"
              legendType='none'
              connectNulls
              strokeWidth={1}
              dot={<CustomizedDot/>}
            ></Line>
          ))}
         
        </ComposedChart>
        </ResponsiveContainer>
        </>
  );
};
export default ActivityGraph;

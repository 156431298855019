/* eslint-disable */

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PageActivities from '../../pages/PageActivities';
import PageLanguages from '../../pages/PageLanguages';
import PageOverview from '../../pages/PageOverview';
import PageStatistics from '../../pages/PageStatistics';
import PageTaxonomy from '../../pages/PageTaxonomies';
import PageHelp from '../../pages/PageHelp';
import PageAboutUs from '../../pages/PageAboutUs';
import AppSideBarMenu from './AppSideBarMenu';

const AppLayout = () => {
  return (
    <Router>
      <div className='bg-light-main h-auto min-h-screen w-full'>
        <div className='flex justify-between items-center h-full'>
          <div className='w-[200px] h-9/10 mr-6 h-full bg-background-dark fixed top-0 bottom-0'>
            <AppSideBarMenu />
          </div>
          <div
            className={
              'h-full flex flex-col ' +
              'flex-grow ' +
              'mx-2 xl:max-w-5/6 py-8 px-10 ' +
              'ml-[200px] bg-light-main'
            }
          >
            <Routes>
              <Route path='/' element={<PageOverview />} />
              <Route path='/aktiviteter' element={<PageActivities />} />
              <Route
                path='/aktiviteter/statistik/'
                element={<PageStatistics />}
              />
              <Route path='/taxonomi' element={<PageTaxonomy />} />
              <Route path='/sprak' element={<PageLanguages />} />
              <Route path='/help' element={<PageHelp />} />
              <Route path='/about' element={<PageAboutUs />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default AppLayout;

export const taxonomyObjects = [
  {
    'taxonomy': -1,
    'name': 'Inget Loggat',
    'name_EN': 'Inget Loggat',
    'category': 'Inget Loggat',
    'subCategory': 'Inget Loggat',
    'keywords': ['Inget Loggat'],
    'frequency': 0,
    'quickSelection': false,
  },
  // EGENOMSORG
  {
    'taxonomy': 20,
    'name': 'Måltid',
    'name_EN': 'Meal',
    'category': 'Egenomsorg',
    'subCategory': 'Äta',
    'keywords': ['Måltid', 'Äta', 'Käka', 'Mat'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 30,
    'name': 'Frukost',
    'name_EN': 'Breakfast',
    'category': 'Egenomsorg',
    'subCategory': 'Äta',
    'keywords': ['Frukost', 'Äta', 'Käka', 'Mat', 'Morgon'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 40,
    'name': 'Lunch',
    'name_EN': 'Lunch',
    'category': 'Egenomsorg',
    'subCategory': 'Äta',
    'keywords': ['Lunch', 'Äta', 'Käka', 'Mat'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 50,
    'name': 'Mellanmål',
    'name_EN': 'Snack',
    'category': 'Egenomsorg',
    'subCategory': 'Äta',
    'keywords': ['Mellanmål', 'Äta', 'Käka', 'Mat', 'Eftermiddag'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 60,
    'name': 'Middag',
    'name_EN': 'Dinner',
    'category': 'Egenomsorg',
    'subCategory': 'Äta',
    'keywords': ['Middag', 'Äta', 'Käka', 'Mat'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 70,
    'name': 'Kvällsmat',
    'name_EN': 'Supper',
    'category': 'Egenomsorg',
    'subCategory': 'Äta',
    'keywords': ['Kvällsmat', 'Äta', 'Käka', 'Mat', 'Kväll'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 80,
    'name': 'Festa på vin/öl/sprit',
    'name_EN': 'Party on alcohol',
    'category': 'Egenomsorg',
    'subCategory': 'Äta',
    'keywords': ['Fest', 'Festa', 'Alkohol', 'Vin', 'Öl', 'Sprit', 'Party'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 110,
    'name': 'Kaffe/te-fika',
    'name_EN': 'Coffe/Tea',
    'category': 'Egenomsorg',
    'subCategory': 'Äta',
    'keywords': ['Kaffe', 'Te', 'Fika'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 160,
    'name': 'Sova',
    'name_EN': 'Sleep',
    'category': 'Egenomsorg',
    'subCategory': 'Sömn',
    'keywords': ['Sova', 'Sömn', 'Natt', 'Drömma'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 170,
    'name': 'Lägga sig',
    'name_EN': 'Go to bed',
    'category': 'Egenomsorg',
    'subCategory': 'Sömn',
    'keywords': ['Sova', 'Sömn', 'Natt', 'Drömma', 'Kväll'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 250,
    'name': 'Vakna',
    'name_EN': 'Wake up',
    'category': 'Egenomsorg',
    'subCategory': 'Sömn',
    'keywords': ['Vakna', 'Vaken', 'Morgon', 'Drömma'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 260,
    'name': 'Vakna, gå upp',
    'name_EN': 'Wake up, go up',
    'category': 'Egenomsorg',
    'subCategory': 'Sömn',
    'keywords': ['Vakna', 'Vaken', 'Morgon'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 280,
    'name': 'Ligga vaken',
    'name_EN': 'Lay awake',
    'category': 'Egenomsorg',
    'subCategory': 'Sömn',
    'keywords': ['Vaken', 'Slumra', 'Snooza'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 300,
    'name': 'Vila',
    'name_EN': 'Rest',
    'category': 'Egenomsorg',
    'subCategory': 'Sömn',
    'keywords': ['Vila', 'Slumra', 'Chilla', 'Lugn', 'Avkoppling', 'Avslappning'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 320,
    'name': 'Slöa',
    'name_EN': 'Laze',
    'category': 'Egenomsorg',
    'subCategory': 'Sömn',
    'keywords': ['Slöa', 'Chilla', 'Långsam', 'Lata', 'Slappa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 330,
    'name': 'Somna till',
    'name_EN': 'Nod off',
    'category': 'Egenomsorg',
    'subCategory': 'Sömn',
    'keywords': ['Somna', 'Slumra', 'Vila', 'Lugn', 'Avkoppling', 'Avslappning', 'Trött'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 360,
    'name': 'Toalettbestyr',
    'name_EN': 'Toilet managment',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Toalett', 'Toa', 'Toalettbesök', 'WC'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 370,
    'name': 'Borsta tänder',
    'name_EN': 'Brush teeth',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Tänder', 'Borsta tänder', 'Omvårdnad'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 380,
    'name': 'Tvätta sig',
    'name_EN': 'Wash off',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Tvätt', 'Rengöra', 'Skölja', 'Badrum'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 400,
    'name': 'Kontaktlinser',
    'name_EN': 'Contactlenses',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Kontaktlinser', 'Linser', 'Syn'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 410,
    'name': 'Sminka sig',
    'name_EN': 'Makeup',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Smink', 'Sminka', 'Makeup'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 430,
    'name': 'Hårvård',
    'name_EN': 'Hair care',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Hårvård', 'Hår', 'Vård'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 450,
    'name': 'Klippa',
    'name_EN': 'Cut hair',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Klippa', 'Hår', 'Hårvård', 'Frisera'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 470,
    'name': 'Rakning',
    'name_EN': 'Shaving',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Rakning', 'Klippa', 'Hårvård', 'Frisera', 'Barbera'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 480,
    'name': 'Toalettbesök',
    'name_EN': 'Toilet visit',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Toalett', 'Toa', 'Toalettbesök', 'WC'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 490,
    'name': 'Klä om',
    'name_EN': 'Change clothes',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Klä', 'Kläder', 'Klädombyte'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 500,
    'name': 'Ta fram kläder',
    'name_EN': 'Bring out clothes',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Kläder', 'Klädombyte', 'Klä', 'Byta om'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 520,
    'name': 'Fysisk träning',
    'name_EN': 'Physical training',
    'category': 'Egenomsorg',
    'subCategory': 'Träning',
    'keywords': ['Träning', 'Aktivitet', 'Motion', 'Träningspass'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 540,
    'name': 'Löpning',
    'name_EN': 'Running',
    'category': 'Egenomsorg',
    'subCategory': 'Träning',
    'keywords': ['Löpning', 'Springa', 'Motion', 'Träning'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 550,
    'name': 'Simning',
    'name_EN': 'Swimming',
    'category': 'Egenomsorg',
    'subCategory': 'Träning',
    'keywords': ['Simning', 'Simma', 'Motion', 'Aktivitet', 'Träning', 'Idrott'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 560,
    'name': 'Gymnastik',
    'name_EN': 'Gymnastics',
    'category': 'Egenomsorg',
    'subCategory': 'Träning',
    'keywords': ['Gymnastik', 'Träning', 'Motion', 'Gympa', 'Idrott'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 570,
    'name': 'Övrig idrott',
    'name_EN': 'Other sports',
    'category': 'Egenomsorg',
    'subCategory': 'Träning',
    'keywords': ['Idrott', 'Sport', 'Träning', 'Motion', 'Dans'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 610,
    'name': 'Fotboll',
    'name_EN': 'Soccer',
    'category': 'Egenomsorg',
    'subCategory': 'Träning',
    'keywords': ['Fotboll', 'Träning', 'Idrott', 'Sport', 'Motion'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 620,
    'name': 'Sola',
    'name_EN': 'Tanning',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Sola', 'Sol'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 640,
    'name': 'Dusch',
    'name_EN': 'Shower',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Dusch', 'Duscha', 'Skölja sig', 'Badrum'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 710,
    'name': 'Bastu',
    'name_EN': 'Sauna',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Bastu', 'Basta', 'Sauna', 'Ångbad'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 760,
    'name': 'Läkarvård',
    'name_EN': 'Medical care',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Vård', 'Läkare', 'Läkarvård', 'Sjukhus', 'Hälsa', 'Sjuk'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 770,
    'name': 'Väntan',
    'name_EN': 'Waiting',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Vänta', 'Väntan', 'Avvaktan', 'Dröjsmål', 'Kö'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 780,
    'name': 'Behandling',
    'name_EN': 'Treatment',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Behandling', 'Vård', 'Kur'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 790,
    'name': 'Tandläkarvård',
    'name_EN': 'Dental care',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Tandläkare', 'Tandläkarvård', 'Tandläkarbesök', 'Vård', 'Tänder'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 820,
    'name': 'Hårfrisering',
    'name_EN': 'Hairdressing',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Hårfrisering', 'Hår', 'Klippning', 'Frisering', 'Skönhet'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 850,
    'name': 'Fotvård',
    'name_EN': 'Feet care',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Fotvård', 'Toa', 'Toalettbesök', 'WC'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 880,
    'name': 'Optiker',
    'name_EN': 'Optician',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Optiker', 'Ögon', 'Glasögon', 'Syn'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 940,
    'name': 'Medicinering, egenvård',
    'name_EN': 'Medication, self care',
    'category': 'Egenomsorg',
    'subCategory': 'Hygien',
    'keywords': ['Medicinering', 'Egenvård', 'Medicin', 'WC'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 960,
    'name': 'Komma hem',
    'name_EN': 'Come home',
    'category': 'Egenomsorg',
    'subCategory': 'Gå ut/komma hem',
    'keywords': ['Hem', 'Gå ut', 'Hemma'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 980,
    'name': 'Gå ut',
    'name_EN': 'Go out',
    'category': 'Egenomsorg',
    'subCategory': 'Gå ut/komma hem',
    'keywords': ['Gå ut', 'Lämna hemmet', 'Utomhus'],
    'frequency': 0,
    'quickSelection': false,
  },
  // OMVÅRDNAD
  {
    'taxonomy': 1020,
    'name': 'Ge djur mat',
    'name_EN': 'Feed pet',
    'category': 'Omvårdnad',
    'subCategory': 'Mata',
    'keywords': ['Djur', 'Mata', 'Föda', 'Husdjur'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1050,
    'name': 'Mata barn',
    'name_EN': 'Feed child',
    'category': 'Omvårdnad',
    'subCategory': 'Mata',
    'keywords': ['Barn', 'Mata', 'Föda', 'Barnvård'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1060,
    'name': 'Ge barn mat',
    'name_EN': 'Give food to child',
    'category': 'Omvårdnad',
    'subCategory': 'Mata',
    'keywords': ['Barn', 'Mata', 'Föda', 'Barnvård', 'Mat'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1070,
    'name': 'Amma',
    'name_EN': 'Amma',
    'category': 'Omvårdnad',
    'subCategory': 'Mata',
    'keywords': ['Barn', 'Mata', 'Föda', 'Barnvård', 'Mat', 'Amma'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1100,
    'name': 'Ge gamla mat',
    'name_EN': 'Feed elderly',
    'category': 'Omvårdnad',
    'subCategory': 'Mata',
    'keywords': ['Gamla', 'Äldre', 'Föda', 'vård', 'Mat', 'Mata'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1160,
    'name': 'Klä på',
    'name_EN': 'Dress',
    'category': 'Omvårdnad',
    'subCategory': 'Klä på/av',
    'keywords': ['Kläder', 'Ombytnad', 'Klä sig'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1170,
    'name': 'Ta fram kläder',
    'name_EN': 'Bring out clothes',
    'category': 'Omvårdnad',
    'subCategory': 'Klä på/av',
    'keywords': ['Kläder', 'Klä sig', 'Ombyte'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1250,
    'name': 'Klä av',
    'name_EN': 'Undress',
    'category': 'Omvårdnad',
    'subCategory': 'Klä på/av',
    'keywords': ['Kläder', 'Klä av', 'Ombyte'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1280,
    'name': 'Lägga undan/hänga upp kläder',
    'name_EN': 'Store clothing',
    'category': 'Omvårdnad',
    'subCategory': 'Klä på/av',
    'keywords': ['Kläder', 'Klä av', 'Ombyte', 'Garderob'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1310,
    'name': 'Tvätta',
    'name_EN': 'Laundry',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Tvätta', 'Skölja', 'Rena', 'Rengöra'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1330,
    'name': 'Tvätta händer',
    'name_EN': 'Wash hands',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Tvätta', 'Skölja', 'Rena', 'Rengöra', 'Händer'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1340,
    'name': 'Tvätta ansikte',
    'name_EN': 'Wash face',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Tvätta', 'Skölja', 'Rena', 'Rengöra', 'Ansikte'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1350,
    'name': 'Tvätta fötter',
    'name_EN': 'Wash feet',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Tvätta', 'Skölja', 'Rena', 'Rengöra', 'Fötter'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1360,
    'name': 'Torka',
    'name_EN': 'Dry',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Torka', 'Torr'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1400,
    'name': 'Borsta tänder',
    'name_EN': 'Brush teeth',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Tänder', 'Rengöra', 'Rena', 'Rengöra', 'Borsta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1420,
    'name': 'Tvätta hår',
    'name_EN': 'Wash hair',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Tvätta', 'Skölja', 'Rena', 'Rengöra', 'Hår', 'Duscha'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1430,
    'name': 'Klippa hår',
    'name_EN': 'Cut hair',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Klippa', 'Hår', 'Trimma', 'Frisera', 'Frisyr'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1440,
    'name': 'Kamma/borsta hår',
    'name_EN': 'Comb/brush hair',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Kamma', 'Hår', 'Borsta', 'Frisera', 'Frisyr'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1450,
    'name': 'Sårvård',
    'name_EN': 'Wound care',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Sår', 'Sårvård', 'plåstra', 'Skada', 'Vård'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1500,
    'name': 'Torka/byta på',
    'name_EN': 'Dry/change',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Torka', 'Byta', 'Barn'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1510,
    'name': 'Byta blöja',
    'name_EN': 'Change diapers',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Blöja', 'Blöjbyte', 'Barn'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1540,
    'name': 'Tömma potta',
    'name_EN': 'Empty pot',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Potta', 'Rengöra', 'Barn'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1610,
    'name': 'Bada',
    'name_EN': 'Bathing',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Bada', 'Duscha', 'Skölja', 'Rengöra', 'Badrum'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1650,
    'name': 'Duscha',
    'name_EN': 'Showering',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Bada', 'Duscha', 'Skölja', 'Rengöra', 'Badrum', 'Dusch'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1700,
    'name': 'Vårdbesök',
    'name_EN': 'Health care visit',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Vård', 'Sjukhus', 'Hälsa', 'Vårdhem', 'Sjukvård'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1710,
    'name': 'Barnavårdscentral',
    'name_EN': 'Children health care visit',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Vård', 'Sjukhus', 'Barn', 'Sjukvård'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1720,
    'name': 'Väntan',
    'name_EN': 'Waiting',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Vänta', 'Dröjsmål', 'Väntrum', 'Sjukvård'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1730,
    'name': 'Behandling',
    'name_EN': 'Treatment',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Vård', 'Sjukhus', 'Hälsa', 'Behandling', 'Sjukvård'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1740,
    'name': 'Tandläkare',
    'name_EN': 'Dentist',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Vård', 'Hälsa', 'Tandläkare', 'Tänder'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1770,
    'name': 'Läkare',
    'name_EN': 'Doctor',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Vård', 'Sjukhus', 'Hälsa', 'Läkare', 'Sjukvård'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1850,
    'name': 'Föräldrabesök',
    'name_EN': 'Parental visit',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Föräldrabesök', 'Besök', 'Föräldrar', 'Skola'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1860,
    'name': 'Förskola',
    'name_EN': 'Preschool',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Förskola', 'Dagis', 'Lekskola', 'Barn', 'Dagmamma', 'Lekis'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1870,
    'name': 'Skola, fritidshem',
    'name_EN': 'School, leisure center',
    'category': 'Omvårdnad',
    'subCategory': 'Sköta hygien',
    'keywords': ['Skola', 'Fritid', 'Barn'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1910,
    'name': 'Leka med (inne)',
    'name_EN': 'Play with, indoors',
    'category': 'Omvårdnad',
    'subCategory': 'Leka',
    'keywords': ['Leka', 'Fritid', 'Barn'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1920,
    'name': 'Spela spel',
    'name_EN': 'Play games',
    'category': 'Omvårdnad',
    'subCategory': 'Leka',
    'keywords': ['Leka', 'Fritid', 'Barn', 'Spela', 'Spel'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1950,
    'name': 'Läser för',
    'name_EN': 'Read for',
    'category': 'Omvårdnad',
    'subCategory': 'Leka',
    'keywords': ['Läsa', 'Fritid', 'Bok'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1960,
    'name': 'Sjunger för',
    'name_EN': 'Sing for',
    'category': 'Omvårdnad',
    'subCategory': 'Leka',
    'keywords': ['Sjunga', 'Fritid', 'Musik'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1970,
    'name': 'Ser på tv med',
    'name_EN': 'Watch tv with',
    'category': 'Omvårdnad',
    'subCategory': 'Leka',
    'keywords': ['TV', 'Sällskap', 'Umgås', 'Film', 'Serie', 'Dokumentär'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 1980,
    'name': 'Spela dator/TV-spel/lek',
    'name_EN': 'Play computer/console games',
    'category': 'Omvårdnad',
    'subCategory': 'Leka',
    'keywords': ['Leka', 'Fritid', 'Spela', 'Data', 'TV-spel'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2000,
    'name': 'Leka ute, lekplats, trädgård',
    'name_EN': 'Play outside',
    'category': 'Omvårdnad',
    'subCategory': 'Leka',
    'keywords': ['Leka', 'Fritid', 'Barn', 'Lekplats', 'Trädgård', 'Utomhus'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2010,
    'name': 'Spela spel/kort',
    'name_EN': 'Play cards',
    'category': 'Omvårdnad',
    'subCategory': 'Leka',
    'keywords': ['Spel', 'Fritid', 'Spela', 'Kort', 'Kortspel'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2020,
    'name': 'Gunga mm.',
    'name_EN': 'Swings etc.',
    'category': 'Omvårdnad',
    'subCategory': 'Leka',
    'keywords': ['Leka', 'Fritid', 'Barn', 'Gunga', 'Lekplats'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2030,
    'name': 'Medverka vid sportaktivitet',
    'name_EN': 'Participate at sporting activity',
    'category': 'Omvårdnad',
    'subCategory': 'Leka',
    'keywords': ['Sport', 'Aktivitet', 'Träning', 'Motion', 'Idrott'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2060,
    'name': 'Hämta',
    'name_EN': 'Pick up',
    'category': 'Omvårdnad',
    'subCategory': 'Sammanföra andra',
    'keywords': ['Hämta', 'Upphämtning', 'Upphämta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2100,
    'name': 'Lämna',
    'name_EN': 'Drop off',
    'category': 'Omvårdnad',
    'subCategory': 'Sammanföra andra',
    'keywords': ['Lämna', 'Överlämna', 'Utlämna', 'Leverera'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2160,
    'name': 'Lägga',
    'name_EN': 'Lay',
    'category': 'Omvårdnad',
    'subCategory': 'Somna',
    'keywords': ['Lägga', 'Sömn', 'Sova', 'Barn', 'Kväll', 'Natta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2170,
    'name': 'Lägga för natten',
    'name_EN': 'Lay for the night',
    'category': 'Omvårdnad',
    'subCategory': 'Somna',
    'keywords': ['Lägga', 'Sömn', 'Sova', 'Barn', 'Kväll', 'Natta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2180,
    'name': 'Läsa',
    'name_EN': 'Reading',
    'category': 'Omvårdnad',
    'subCategory': 'Somna',
    'keywords': ['Läsa', 'Bok', 'Saga', 'Natta', 'Barn', 'Kväll'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2190,
    'name': 'Sjunga',
    'name_EN': 'Sing',
    'category': 'Omvårdnad',
    'subCategory': 'Somna',
    'keywords': ['Sång', 'Sjunga', 'Musik'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2200,
    'name': 'Köra vagn, bära på',
    'name_EN': 'Pram, carry',
    'category': 'Omvårdnad',
    'subCategory': 'Somna',
    'keywords': ['Vagn', 'Barn', 'Bebis', 'Barnvagn', 'Bära'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2210,
    'name': 'Sitta hos',
    'name_EN': 'Sit with',
    'category': 'Omvårdnad',
    'subCategory': 'Somna',
    'keywords': ['Sitta', 'Sällskap', 'Umgås'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2260,
    'name': 'Kör vagn',
    'name_EN': 'Drive a pram',
    'category': 'Omvårdnad',
    'subCategory': 'Somna',
    'keywords': ['Vagn', 'Skjutsa', 'Barnvagn', 'Barn'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2300,
    'name': 'Somna om under natten',
    'name_EN': 'Go back to sleep',
    'category': 'Omvårdnad',
    'subCategory': 'Somna',
    'keywords': ['Sömn', 'Sova', 'Natt', 'Somna om'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2310,
    'name': 'Bära tillbaka barn till dess egen säng',
    'name_EN': 'Carry back to bed',
    'category': 'Omvårdnad',
    'subCategory': 'Somna',
    'keywords': ['Lägga', 'Sömn', 'Sova', 'Barn', 'Kväll', 'Natta', 'Säng', 'Barnsäng'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2320,
    'name': 'Byta till barnets säng',
    'name_EN': 'Change to childrens bed',
    'category': 'Omvårdnad',
    'subCategory': 'Somna',
    'keywords': ['Lägga', 'Sömn', 'Sova', 'Barn', 'Kväll', 'Natta', 'Säng', 'Barnsäng'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2360,
    'name': 'Väcka vuxna',
    'name_EN': 'Wake up adults',
    'category': 'Omvårdnad',
    'subCategory': 'Somna',
    'keywords': ['Vakna', 'Väcka'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2370,
    'name': 'Väcka barn',
    'name_EN': 'Wake up child',
    'category': 'Omvårdnad',
    'subCategory': 'Somna',
    'keywords': ['Väcka', 'Vakna'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2410,
    'name': 'Säga åt',
    'name_EN': 'Tell',
    'category': 'Omvårdnad',
    'subCategory': 'Hjälpa/uppfostra',
    'keywords': ['Beordra', 'Säga till', 'Tillsäga'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2420,
    'name': 'Trösta',
    'name_EN': 'Comfort',
    'category': 'Omvårdnad',
    'subCategory': 'Hjälpa/uppfostra',
    'keywords': ['Trösta', 'Lugna', 'Stödja', 'Lindra', 'Stilla'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2430,
    'name': 'Gosa',
    'name_EN': 'Cuddle',
    'category': 'Omvårdnad',
    'subCategory': 'Hjälpa/uppfostra',
    'keywords': ['Gosa', 'Kramas', 'Kela'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2440,
    'name': 'Titta till',
    'name_EN': 'Take a look',
    'category': 'Omvårdnad',
    'subCategory': 'Hjälpa/uppfostra',
    'keywords': ['Tillsyn', 'Uppsikt', 'Övervaka', 'Uppsyn', 'Eftersyn'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2450,
    'name': 'Leta åt',
    'name_EN': 'Look for',
    'category': 'Omvårdnad',
    'subCategory': 'Hjälpa/uppfostra',
    'keywords': ['Leta', 'Söka'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2460,
    'name': 'Hjälpa med skolarbete',
    'name_EN': 'Help with homework',
    'category': 'Omvårdnad',
    'subCategory': 'Hjälpa/uppfostra',
    'keywords': ['Hjälpa', 'Skola', 'Skolarbete', 'Läxor', 'Undervisning'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2470,
    'name': 'Hjälpa med annan verksamhet',
    'name_EN': 'Help with other activity',
    'category': 'Omvårdnad',
    'subCategory': 'Hjälpa/uppfostra',
    'keywords': ['Hjälpa', 'Verksamhet'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2480,
    'name': 'Hämta något åt någon',
    'name_EN': 'Pick something up for someone',
    'category': 'Omvårdnad',
    'subCategory': 'Hjälpa/uppfostra',
    'keywords': ['Hjälpa', 'Hämta', 'Leverera', 'Upphämta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2490,
    'name': 'Lämna något åt någon',
    'name_EN': 'Drop something off for someone',
    'category': 'Omvårdnad',
    'subCategory': 'Hjälpa/uppfostra',
    'keywords': ['Lämna', 'Avlämna', 'Leverera'],
    'frequency': 0,
    'quickSelection': false,
  },
  // HUSHÅLLSVÅRD
  {
    'taxonomy': 2520,
    'name': 'Städa',
    'name_EN': 'Clean',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Städa', 'Rena', 'Rengöra', 'Ordna', 'plocka', 'Rensa', 'Röja'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2540,
    'name': 'Sopa',
    'name_EN': 'Brush',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Städa', 'Rena', 'Rengöra', 'Sopa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2550,
    'name': 'Dammsuga',
    'name_EN': 'Vaccum',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Städa', 'Rena', 'Rengöra', 'Dammsuga'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2560,
    'name': 'Våttorka/skura',
    'name_EN': 'Mop dry',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Städa', 'Rena', 'Rengöra', 'Skura'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2570,
    'name': 'Dammtorka',
    'name_EN': 'Dust dry',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Städa', 'Rena', 'Rengöra', 'Dammtorka'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2580,
    'name': 'Vädra',
    'name_EN': 'Ventilate',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Städa', 'Rena', 'Vädra', 'Lufta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2600,
    'name': 'Storstäda',
    'name_EN': 'Great cleaning',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Städa', 'Rena', 'Rengöra'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2650,
    'name': 'Putsa fönster',
    'name_EN': 'Clean windows',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Städa', 'Rena', 'Rengöra', 'Fönster', 'Putsa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2700,
    'name': 'Bädda',
    'name_EN': 'Making bed',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Bädda', 'Säng'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2720,
    'name': 'Byta sängkläder',
    'name_EN': 'Change bedding',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Städa', 'Rena', 'Sängkläder', 'Byta sängkläder'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2750,
    'name': 'Plocka undan',
    'name_EN': 'Stash',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Städa', 'Rena', 'Rengöra', 'Plocka'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2760,
    'name': 'Pynta, ta fram',
    'name_EN': 'Decorate',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Pynta', 'Pryda', 'Dekorera'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2770,
    'name': 'Blomvård',
    'name_EN': 'Flower care',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Blomvård', 'Blommor', 'Växter', 'Vattna'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2780,
    'name': 'Vattna',
    'name_EN': 'Watering',
    'category': 'Hemvård',
    'subCategory': 'Rumsvård',
    'keywords': ['Vattna', 'Växter', 'Blommor', 'bevattna'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2810,
    'name': 'Tvätta',
    'name_EN': 'Wash',
    'category': 'Hemvård',
    'subCategory': 'Klädvård',
    'keywords': ['Tvätta', 'Rena', 'Rengöra', 'Kläder'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2820,
    'name': 'Handtvätt',
    'name_EN': 'Handwash',
    'category': 'Hemvård',
    'subCategory': 'Klädvård',
    'keywords': ['Tvätta', 'Rena', 'Rengöra', 'Handtvätt', 'Kläder'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2830,
    'name': 'Maskintvätt',
    'name_EN': 'Machine wash',
    'category': 'Hemvård',
    'subCategory': 'Klädvård',
    'keywords': ['Tvätta', 'Rena', 'Rengöra', 'Maskintvätt', 'Kläder'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2840,
    'name': 'Lägga in i maskin',
    'name_EN': 'Put in machine',
    'category': 'Hemvård',
    'subCategory': 'Klädvård',
    'keywords': ['Tvätta', 'Rena', 'Rengöra', 'Maskintvätt', 'Kläder'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2850,
    'name': 'Tömma maskin',
    'name_EN': 'Empty machine',
    'category': 'Hemvård',
    'subCategory': 'Klädvård',
    'keywords': ['Tvätta', 'Tvättmaskin', 'Kläder'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2880,
    'name': 'Hänga tvätt/lägga i torktumlare',
    'name_EN': 'Drying',
    'category': 'Hemvård',
    'subCategory': 'Klädvård',
    'keywords': ['Hänga tvätt', 'Torktumlare', 'Kläder', 'Torka'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2950,
    'name': 'Stryka',
    'name_EN': 'Ironing',
    'category': 'Hemvård',
    'subCategory': 'Klädvård',
    'keywords': ['Stryka', 'Pressa', 'Kläder'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 2960,
    'name': 'Mangla',
    'name_EN': 'Mangle',
    'category': 'Hemvård',
    'subCategory': 'Klädvård',
    'keywords': ['Mangla', 'Pressa', 'Stryka', 'Släta', 'Kläder'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3000,
    'name': 'Sortera',
    'name_EN': 'Sort',
    'category': 'Hemvård',
    'subCategory': 'Klädvård',
    'keywords': ['Sortera', 'Ordna', 'Indela', 'Uppdela', 'Fördela'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3030,
    'name': 'Lägga/hänga in ren tvätt',
    'name_EN': 'Store clean laundry',
    'category': 'Hemvård',
    'subCategory': 'Klädvård',
    'keywords': ['Tvätta', 'Hänga', 'Torka', 'Tvätt', 'Kläder'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3130,
    'name': 'Sy',
    'name_EN': 'Sew',
    'category': 'Hemvård',
    'subCategory': 'Sakvård',
    'keywords': ['Sy', 'Fixa', 'Reparera'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3170,
    'name': 'Laga, förnya',
    'name_EN': 'Fix',
    'category': 'Hemvård',
    'subCategory': 'Sakvård',
    'keywords': ['Laga', 'Förnya', 'Fixa', 'Reparera'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3210,
    'name': 'Renovera, ute',
    'name_EN': 'Renovate',
    'category': 'Hemvård',
    'subCategory': 'Sakvård',
    'keywords': ['Renovera', 'Fixa', 'Reparera', 'Bygga'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3240,
    'name': 'Bilvård',
    'name_EN': 'Car care',
    'category': 'Hemvård',
    'subCategory': 'Sakvård',
    'keywords': ['Bilvård', 'Fixa', 'Reparera', 'Bil'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3300,
    'name': 'Båtvård',
    'name_EN': 'Boat care',
    'category': 'Hemvård',
    'subCategory': 'Sakvård',
    'keywords': ['Båtvård', 'Båt', 'Reparera', 'Fixa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3380,
    'name': 'Cykel/moped-vård',
    'name_EN': 'Bike care',
    'category': 'Hemvård',
    'subCategory': 'Sakvård',
    'keywords': ['Cykelvård', 'Cykel', 'Moped', 'Reparera', 'Sköta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3440,
    'name': 'Övrigt mekande',
    'name_EN': 'Other care',
    'category': 'Hemvård',
    'subCategory': 'Sakvård',
    'keywords': ['Mekande', 'Dona', 'Reparera', 'Fixa', 'Greja'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3460,
    'name': 'Hushållsbudget',
    'name_EN': 'Housing budget',
    'category': 'Hemvård',
    'subCategory': 'Boendeadministration',
    'keywords': ['Hushållsbudget', 'Hemmet', 'Budget', 'Ekonomi'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3470,
    'name': 'Planering',
    'name_EN': 'Planning',
    'category': 'Hemvård',
    'subCategory': 'Boendeadministration',
    'keywords': ['Planering', 'Förberedelse', 'Organisera', 'Planera'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3480,
    'name': 'Räkningar',
    'name_EN': 'Bills',
    'category': 'Hemvård',
    'subCategory': 'Boendeadministration',
    'keywords': ['Räkningar', 'Ekonomi', 'Hemmet', 'Betala'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3540,
    'name': 'Posten',
    'name_EN': 'Mail',
    'category': 'Hemvård',
    'subCategory': 'Boendeadministration',
    'keywords': ['Posten', 'Post', 'Brev', 'Sortera'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3550,
    'name': 'Bankärende',
    'name_EN': 'Banking matter',
    'category': 'Hemvård',
    'subCategory': 'Boendeadministration',
    'keywords': ['Bank', 'Bankärende', 'Budget', 'Ekonomi', 'Pengar'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3630,
    'name': 'Titta på möbler',
    'name_EN': 'Look at furniture',
    'category': 'Hemvård',
    'subCategory': 'Inköp',
    'keywords': ['Möbler', 'Shopping', 'Inredning', 'Shoppa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3640,
    'name': 'Köpa möbler',
    'name_EN': 'Buying furniture',
    'category': 'Hemvård',
    'subCategory': 'Inköp',
    'keywords': ['Möbler', 'Shopping', 'Inredning', 'Shoppa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3690,
    'name': 'Titta på kläder',
    'name_EN': 'Look at clothes',
    'category': 'Hemvård',
    'subCategory': 'Inköp',
    'keywords': ['Kläder', 'Shopping', 'Mode', 'Shoppa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3700,
    'name': 'Köpa kläder',
    'name_EN': 'Buying clothes',
    'category': 'Hemvård',
    'subCategory': 'Inköp',
    'keywords': ['Kläder', 'Shopping', 'Mode', 'Shoppa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3760,
    'name': 'Övriga inköp',
    'name_EN': 'Other purches',
    'category': 'Hemvård',
    'subCategory': 'Inköp',
    'keywords': ['Inköp', 'Shopping', 'Shoppa', 'Köpa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3810,
    'name': 'Tomten/balkongen',
    'name_EN': 'Yard/balcony',
    'category': 'Hemvård',
    'subCategory': 'Trädgårdsvård',
    'keywords': ['Tomt', 'Hem', 'Balkong', 'bygga'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3830,
    'name': 'Välja/titta på växter',
    'name_EN': 'Pick plants',
    'category': 'Hemvård',
    'subCategory': 'Trädgårdsvård',
    'keywords': ['Växter', 'Plantor', 'Blommor', 'Shoppa', 'Shopping'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3920,
    'name': 'Så',
    'name_EN': 'Sow',
    'category': 'Hemvård',
    'subCategory': 'Trädgårdsvård',
    'keywords': ['Växter', 'Så', 'Plantor', 'Blommor', 'Trädgårdsarbete', 'Odla'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3930,
    'name': 'Plantera',
    'name_EN': 'Plant',
    'category': 'Hemvård',
    'subCategory': 'Trädgårdsvård',
    'keywords': ['Plantera', 'Växter', 'Blommor', 'Trädgårdsarbete', 'Odla'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3940,
    'name': 'Vattna',
    'name_EN': 'Watering',
    'category': 'Hemvård',
    'subCategory': 'Trädgårdsvård',
    'keywords': ['Vattna', 'Växter', 'Blommor', 'Plantor'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 3980,
    'name': 'Underhållsarbete',
    'name_EN': 'Maintenance work',
    'category': 'Hemvård',
    'subCategory': 'Trädgårdsvård',
    'keywords': ['Underhållsarbete', 'bygga', 'Renovera', 'Fixa', 'Städa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4020,
    'name': 'Klippa gräs',
    'name_EN': 'Cut grass',
    'category': 'Hemvård',
    'subCategory': 'Trädgårdsvård',
    'keywords': ['Gräs', 'Klippa', 'Gräsklippare', 'Trädgårdsarbete'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4060,
    'name': 'Plocka blommor',
    'name_EN': 'Pick flowers',
    'category': 'Hemvård',
    'subCategory': 'Trädgårdsvård',
    'keywords': ['Blommor', 'Plantor', 'Trädgård', 'Växter'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4120,
    'name': 'Sy',
    'name_EN': 'Sew',
    'category': 'Hemvård',
    'subCategory': 'Konstruktion',
    'keywords': ['Sy', 'Reparera', 'Hobby'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4130,
    'name': 'Sticka',
    'name_EN': 'Knit',
    'category': 'Hemvård',
    'subCategory': 'Konstruktion',
    'keywords': ['Sticka', 'Hobby'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4140,
    'name': 'Virka',
    'name_EN': 'Crochet',
    'category': 'Hemvård',
    'subCategory': 'Konstruktion',
    'keywords': ['Virka', 'Hobby'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4200,
    'name': 'Trä-metall',
    'name_EN': 'Wood-metal',
    'category': 'Hemvård',
    'subCategory': 'Konstruktion',
    'keywords': ['Trä', 'Metall', 'Bygga'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4210,
    'name': 'Snickra',
    'name_EN': 'Carpenting',
    'category': 'Hemvård',
    'subCategory': 'Konstruktion',
    'keywords': ['Snickra', 'Reparera', 'Bygga'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4230,
    'name': 'Måla',
    'name_EN': 'Painting',
    'category': 'Hemvård',
    'subCategory': 'Konstruktion',
    'keywords': ['Måla', 'Reparera', 'Hem', 'Fixa', 'Renovera'],
    'frequency': 0,
    'quickSelection': false,
  },
  // AVKOPPLING
  {
    'taxonomy': 4520,
    'name': 'Telefon',
    'name_EN': 'Phone',
    'category': 'Avkoppling',
    'subCategory': 'Social avkoppling',
    'keywords': ['Telefon', 'Mobil', 'Samtal', 'Umgås', 'Prata'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4550,
    'name': 'Få gäster',
    'name_EN': 'Have guests',
    'category': 'Avkoppling',
    'subCategory': 'Social avkoppling',
    'keywords': ['Gäster', 'Besök', 'Umgås', 'Prata'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4570,
    'name': 'Bjudning, fest',
    'name_EN': 'Party',
    'category': 'Avkoppling',
    'subCategory': 'Social avkoppling',
    'keywords': ['Bjudning', 'Fest', 'Besök', 'Umgås', 'Prata', 'Gäster', 'Party', 'Vänner'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4600,
    'name': 'Hälsa på vänner',
    'name_EN': 'Visit friends',
    'category': 'Avkoppling',
    'subCategory': 'Social avkoppling',
    'keywords': ['Besök', 'Bjudning', 'Vänner', 'Umgås', 'Prata'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4640,
    'name': 'Dans',
    'name_EN': 'Dance',
    'category': 'Avkoppling',
    'subCategory': 'Social avkoppling',
    'keywords': ['Dans', 'Fest', 'Aktivitet', 'Umgås'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4660,
    'name': 'Samtalar',
    'name_EN': 'Conversation',
    'category': 'Avkoppling',
    'subCategory': 'Social avkoppling',
    'keywords': ['Samtala', 'Umgås', 'Prata', 'Social'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4690,
    'name': 'Religiös ceremoni',
    'name_EN': 'Religious ceremony',
    'category': 'Avkoppling',
    'subCategory': 'Social avkoppling',
    'keywords': ['Religion', 'Ceremoni', 'Gudstjänst'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4740,
    'name': 'Delta i spel',
    'name_EN': 'Partake in game',
    'category': 'Avkoppling',
    'subCategory': 'Social avkoppling',
    'keywords': ['Tävling', 'Lotteri', 'Spela', 'Spel'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4760,
    'name': 'Läsa',
    'name_EN': 'Reading',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Läsa', 'Bok', 'Tidning', 'Hobby'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4790,
    'name': 'Läsa tidning',
    'name_EN': 'Reading magazine',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Läsa', 'Tidning', 'Hobby'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4800,
    'name': 'Bok',
    'name_EN': 'Book',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Läsa', 'Bok', 'Hobby'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4810,
    'name': 'Lyssna',
    'name_EN': 'Listen',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Lyssna', 'Höra', 'Beakta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4820,
    'name': 'Radio',
    'name_EN': 'Radio',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Lyssna', 'Höra', 'Beakta', 'Radio'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4840,
    'name': 'Levande musik',
    'name_EN': 'Live music',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Lyssna', 'Höra', 'Beakta', 'Konsert', 'Spelning', 'Musik'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4860,
    'name': 'TV',
    'name_EN': 'TV',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Titta', 'Se', 'Avkoppling', 'TV'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4870,
    'name': 'Bio',
    'name_EN': 'Cinema',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Bio', 'Titta', 'Umgås', 'Biograf'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4880,
    'name': 'Köpa biljett',
    'name_EN': 'Buy tickets',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Köpa', 'Biljett', 'Aktivitet'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4900,
    'name': 'Teater',
    'name_EN': 'Theater',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Teater', 'Titta', 'Umgås', 'Evenemang', 'Hobby'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4930,
    'name': 'Konst',
    'name_EN': 'Art',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Konst', 'Se', 'Titta', 'Museum', 'Galleri', 'Utställning'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4950,
    'name': 'Musicera',
    'name_EN': 'Play music',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Musik', 'Spela', 'Sjunga', 'Jamma', 'Lira'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4970,
    'name': 'Rita, måla',
    'name_EN': 'Draw, paint',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Rita', 'Måla', 'Hobby', 'Avkoppling'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4980,
    'name': 'Se på bilder',
    'name_EN': 'Watch images',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Se', 'Titta', 'Fotografier', 'Utställning', 'Fotografering'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 4990,
    'name': 'Dator/TV-spel',
    'name_EN': 'Computer / Console-games',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Dator', 'Spel', 'TV-spel', 'Dator-spel'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5000,
    'name': 'Skriver',
    'name_EN': 'Writing',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Skriva', 'Skriver', 'Författa', 'Dikta', 'Komponera'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5010,
    'name': 'Dagbok',
    'name_EN': 'Diary',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Skriva', 'Skriver', 'Dagbok', 'Dikta', 'Komponera', 'Uttrycka'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5020,
    'name': 'Brev',
    'name_EN': 'Letter',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Skriva', 'Skriver', 'Författa', 'Dikta', 'Komponera', 'Brev'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5030,
    'name': 'Dator; skriva, räkna',
    'name_EN': 'Computer; read, write',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Skriva', 'Räkna', 'Dator'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5050,
    'name': 'Föreningsarbete',
    'name_EN': 'Community work',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Föreningsarbete', 'Förening', 'Administration'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5060,
    'name': 'Politisk org.',
    'name_EN': 'Political org.',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Politisk', 'Politik', 'Organisation'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5080,
    'name': 'Idrott',
    'name_EN': 'Sports',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Idrott', 'Träning', 'Aktivitet', 'Sport', 'Hobby'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5150,
    'name': 'Kurs',
    'name_EN': 'Course',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Studier', 'Studera', 'Skola', 'Skolarbete'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5210,
    'name': 'Idrottsrörelse',
    'name_EN': 'Sportsmovment',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Idrott', 'Träning', 'Aktivitet', 'Sport', 'Hobby', 'Rörelse', 'Idrottsrörelse'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5370,
    'name': 'Kärlekshandlingar',
    'name_EN': 'Love actions',
    'category': 'Avkoppling',
    'subCategory': 'Social avkoppling',
    'keywords': ['Kärlek', 'Par', 'Handlingar', 'Aktivitet'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5400,
    'name': 'Snusa, röka',
    'name_EN': 'Smoke',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Snusa', 'Röka', 'Nikotin'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5420,
    'name': 'E-post',
    'name_EN': 'Email',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Mail', 'Mejl', 'E-post'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5430,
    'name': 'Chatta',
    'name_EN': 'Chat',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Chatt', 'Chat', 'Chatta', 'Umgås', 'Social', 'Vänner'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5440,
    'name': 'Surfa/sociala medier',
    'name_EN': 'Browsing/social media',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Surfa', 'Social media', 'Internet', 'Sociala medier'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5450,
    'name': 'Online spel',
    'name_EN': 'Online gaming',
    'category': 'Avkoppling',
    'subCategory': 'Enskild avkoppling',
    'keywords': ['Spel', 'Online', 'Umgås', 'Sällskap', 'Hobby'],
    'frequency': 0,
    'quickSelection': false,
  },
  // FÖRFLYTTNING
  {
    'taxonomy': 5520,
    'name': 'Bil',
    'name_EN': 'Car',
    'category': 'Förflyttning',
    'subCategory': 'Resa/förflyttning',
    'keywords': ['Bil', 'Resa', 'Åka', 'Fordon'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5530,
    'name': 'Hämta',
    'name_EN': 'Pickup',
    'category': 'Förflyttning',
    'subCategory': 'Resa/förflyttning',
    'keywords': ['Hämta', 'Hemföra', 'Inhämta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5660,
    'name': 'Promenera, gå',
    'name_EN': 'Walk',
    'category': 'Förflyttning',
    'subCategory': 'Resa/förflyttning',
    'keywords': ['Promenera', 'Gå', 'Vandra', 'Traska', 'Strosa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5670,
    'name': 'Gå med hunden',
    'name_EN': 'Walk with dog',
    'category': 'Förflyttning',
    'subCategory': 'Resa/förflyttning',
    'keywords': ['Promenera', 'Hund', 'Rasta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5680,
    'name': 'Gå med barnvagn',
    'name_EN': 'Walk with stroller',
    'category': 'Förflyttning',
    'subCategory': 'Resa/förflyttning',
    'keywords': ['Barnvagn', 'Barn', 'Promenera', 'Gå'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5750,
    'name': 'Cykel',
    'name_EN': 'Biking',
    'category': 'Förflyttning',
    'subCategory': 'Resa/förflyttning',
    'keywords': ['Cykel', 'Transport', 'Cykla'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5850,
    'name': 'Buss/spårvagn',
    'name_EN': 'Buss/tram',
    'category': 'Förflyttning',
    'subCategory': 'Resa/förflyttning',
    'keywords': ['Buss', 'Resa', 'Åka', 'Fordon', 'Spårvagn', 'Kollektivt'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 5950,
    'name': 'Tåg',
    'name_EN': 'Train',
    'category': 'Förflyttning',
    'subCategory': 'Resa/förflyttning',
    'keywords': ['Tåg', 'Resa', 'Åka', 'Fordon', 'Kollektivt'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6050,
    'name': 'Flyg',
    'name_EN': 'Flight',
    'category': 'Förflyttning',
    'subCategory': 'Resa/förflyttning',
    'keywords': ['Flyg', 'Resa', 'Åka', 'Fordon', 'Flyga'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6100,
    'name': 'Båt',
    'name_EN': 'Boat',
    'category': 'Förflyttning',
    'subCategory': 'Resa/förflyttning',
    'keywords': ['Båt', 'Resa', 'Åka', 'Fordon'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6200,
    'name': 'Moped/motorcykel',
    'name_EN': 'Moped/motorbike',
    'category': 'Förflyttning',
    'subCategory': 'Resa/förflyttning',
    'keywords': ['Moped', 'Motorcykel', 'Åka', 'Fordon'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6300,
    'name': 'Taxi',
    'name_EN': 'Taxi',
    'category': 'Förflyttning',
    'subCategory': 'Resa/förflyttning',
    'keywords': ['Bil', 'Resa', 'Åka', 'Fordon', 'Taxi'],
    'frequency': 0,
    'quickSelection': false,
  },
  // MATHÅLLNING
  {
    'taxonomy': 6520,
    'name': 'Handla mat',
    'name_EN': 'Purchase food',
    'category': 'Fixa mat',
    'subCategory': 'Införskaffa',
    'keywords': ['Handla', 'Mat', 'Shoppa', 'Matbutik'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6530,
    'name': 'Planera',
    'name_EN': 'Planning',
    'category': 'Fixa mat',
    'subCategory': 'Införskaffa',
    'keywords': ['Planera', 'Planlägga', 'Ordna'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6550,
    'name': 'Plocka in/upp/hämta varor',
    'name_EN': 'Pick up goods',
    'category': 'Fixa mat',
    'subCategory': 'Införskaffa',
    'keywords': ['Handla', 'Mat', 'Shoppa', 'Varor', 'Plocka', 'Hämta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6560,
    'name': 'Beställa matvaror',
    'name_EN': 'Order food',
    'category': 'Fixa mat',
    'subCategory': 'Införskaffa',
    'keywords': ['Handla', 'Mat', 'Shoppa', 'Beställa', 'Matvaror'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6600,
    'name': 'Skörda',
    'name_EN': 'Harvest',
    'category': 'Fixa mat',
    'subCategory': 'Införskaffa',
    'keywords': ['Skörda', 'Plocka', 'Samla', 'Mat'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6610,
    'name': 'Plocka i skogen',
    'name_EN': 'Picking in forrest',
    'category': 'Fixa mat',
    'subCategory': 'Införskaffa',
    'keywords': ['Plocka', 'Mat', 'Skog'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6650,
    'name': 'Plocka i trädgården',
    'name_EN': 'Picking in the garden',
    'category': 'Fixa mat',
    'subCategory': 'Införskaffa',
    'keywords': ['Plocka', 'Mat', 'Trädgård'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6700,
    'name': 'Fiska',
    'name_EN': 'Fishing',
    'category': 'Fixa mat',
    'subCategory': 'Införskaffa',
    'keywords': ['Fiska', 'Fisk', 'Mat', 'Hobby', 'Aktivitet'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6750,
    'name': 'Jaga',
    'name_EN': 'Hunting',
    'category': 'Fixa mat',
    'subCategory': 'Införskaffa',
    'keywords': ['Jaga', 'Mat', 'Skog', 'Hobby', 'Aktivitet'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6810,
    'name': 'Måltid',
    'name_EN': 'Meal',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Laga mat', 'Mat', 'Måltid', 'Äta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6820,
    'name': 'Förbereda mat',
    'name_EN': 'Prepare food',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Laga mat', 'Mat', 'Måltid', 'Äta', 'Förbereda'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6830,
    'name': 'Duka',
    'name_EN': 'Table cloth',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Duka', 'Mat', 'Måltid', 'Äta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6860,
    'name': 'Servera mat',
    'name_EN': 'Serving food',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Servera mat', 'Mat', 'Måltid', 'Äta', 'Servera'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6910,
    'name': 'Laga mat',
    'name_EN': 'Cooking food',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Laga mat', 'Mat', 'Måltid', 'Äta'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 6950,
    'name': 'Beställa mat',
    'name_EN': 'Ordering food',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Beställa mat', 'Mat', 'Måltid', 'Äta', 'Beställa', 'Hemleverans'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 7020,
    'name': 'Duka av',
    'name_EN': 'Clean the table',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Duka av', 'Mat', 'Måltid'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 7030,
    'name': 'Diska i maskin',
    'name_EN': 'Wash in dishwasher',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Diska', 'Mat', 'Måltid', 'Rengöra', 'Städa', 'Diskmaskin'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 7040,
    'name': 'Diska för hand',
    'name_EN': 'Wash by hand',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Diska', 'Handdisk', 'Städa', 'Rengöra'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 7060,
    'name': 'Plocka undan disk',
    'name_EN': 'Put away dishes',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Städa', 'Disk', 'Plocka', 'Plocka disk'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 7200,
    'name': 'Baka',
    'name_EN': 'Baking',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Baka', 'Fika', 'Äta', 'Bakning'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 7210,
    'name': 'Förbereda bak',
    'name_EN': 'Prepare baking',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Baka', 'Bakning', 'Fika', 'Äta', 'Förbereda'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 7400,
    'name': 'Konservera',
    'name_EN': 'Preserve',
    'category': 'Fixa mat',
    'subCategory': 'Laga',
    'keywords': ['Konservera', 'Mat', 'Bevara'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 7610,
    'name': 'Gå ut med sopor',
    'name_EN': 'Take out the garbage',
    'category': 'Fixa mat',
    'subCategory': 'Avfallshantering',
    'keywords': ['Slänga', 'Avfall', 'Sopor', 'Städa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 7620,
    'name': 'Sophantering',
    'name_EN': 'Garbage disposal',
    'category': 'Fixa mat',
    'subCategory': 'Avfallshantering',
    'keywords': ['Slänga', 'Avfall', 'Sopor', 'Städa'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 7660,
    'name': 'Återvinning',
    'name_EN': 'Recycling',
    'category': 'Fixa mat',
    'subCategory': 'Avfallshantering',
    'keywords': ['Återvinna', 'Avfall', 'Återvinning', 'Sopor', 'Städa', 'Sortering'],
    'frequency': 0,
    'quickSelection': false,
  },
  {
    'taxonomy': 7770,
    'name': 'Ställa ut för hämtning',
    'name_EN': 'Put out for collection',
    'category': 'Fixa mat',
    'subCategory': 'Avfallshantering',
    'keywords': ['Hämtning', 'Avfall', 'Sopor', 'Städa'],
    'frequency': 0,
    'quickSelection': false,
  }, {
    'taxonomy': 9000,
    'name': 'Arbete',
    'name_EN': 'Work',
    'category': 'Arbete/skola',
    'subCategory': 'Arbete/skola',
    'keywords': ['Jobba', 'Arbeta', 'Arbete'],
    'frequency': 0,
    'quickSelection': false,
  },

  {
    'taxonomy': 9500,
    'name': 'Skola',
    'name_EN': 'School',
    'category': 'Arbete/skola',
    'subCategory': 'Arbete/skola',
    'keywords': ['Skola', 'Utbildning'],
    'frequency': 0,
    'quickSelection': false,
  },
];

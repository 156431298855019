/* eslint-disable */
import React from 'react';
import {RecoilRoot} from 'recoil';
import AppLayout from './components/AppLayout/AppLayout';

function App() {
return (
<div className="App">
  <RecoilRoot>
    <AppLayout />
  </RecoilRoot>
</div>
);
}

export default App;

/* eslint-disable no-unused-vars */
export function calcMins(timeStamp: string, nextTimeStamp: string): number {
  const current = getMins(timeStamp);
  const next = getMins(nextTimeStamp);
  return next - current;
}

export function getMins(timeStamp: string) {
  let totMins: number = 0;
  const splitted = timeStamp.split(':', 2);
  const hours: number = parseInt(splitted[0]);
  const mins: number = parseInt(splitted[1]);
  totMins += hours * 60 + mins;

  return totMins;
}

export function calcTotal(arr, taxonomy) {
  let total = 0;
  for (let y = 0; y <arr.length; y++) {
    const act = arr[y].activities;
    for (let i = 0; i<act.length; i++) {
      if (act[i].taxonomy === taxonomy) {
        total++;
      }
    }
  }

  return total;
};

export function calcTotalPerc(arr, taxonomy) {
  let hits = 0;
  let total = 0;

  for (let y = 0; y <arr.length; y++) {
    const act = arr[y].activities;
    total += act.length;
    for (let i = 0; i<act.length; i++) {
      if (act[i].taxonomy === taxonomy) {
        hits++;
      }
    }
  }

  const percentage = hits / total * 100;
  return Math.round(percentage * 10) / 10;
};

export function calTotalNumberOfActivites(arr) {
  let actsTotal = 0;
  for (let i=0; i<arr.length; i++) {
    for (let y=0; y<arr[i].activities.length; y++) {
      actsTotal++;
    }
  }
  return actsTotal;
}
export function calcTotalTimeCategory(arr, category) {
  let current = 0;
  let currentAll = 0;
  const totalMins = 1440;
  for (let y = 0; y<arr.length; y++) {
    const act = arr[y].activities;
    const total = act.length;

    for (let i = 0; i<act.length; i++) {
      if (act[i].category === category) {
        if (i + 1 < total) {
          current += calcMins(act[i].start, act[i + 1].start);
          currentAll += calcMins(act[i].start, act[i + 1].start);
        } else {
          current += totalMins - getMins(act[i].start);
          currentAll += totalMins - getMins(act[i].start);
        }
      } else {
        if (i + 1 < total) {
          currentAll += calcMins(act[i].start, act[i + 1].start);
        } else {
          currentAll += totalMins - getMins(act[i].start);
        }
      }
    }
  }

  const percentage = current / currentAll * 100;
  return Math.round(percentage * 10) / 10;
};

export function calcTotalTime(arr, taxonomy) {
  let current = 0;
  const totalMins = 1440;

  for (let y = 0; y <arr.length; y++) {
    const act = arr[y].activities;
    const total = act.length;

    for (let i = 0; i<act.length; i++) {
      if (act[i].taxonomy === taxonomy) {
        if (i + 1 < total) {
          current += calcMins(act[i].start, act[i + 1].start);
        } else {
          current += totalMins - getMins(act[i].start);
        }
      }
    }
  }

  return current;
};

export function calcTimePercentage(arr, taxonomy) {
  let current = 0;
  let currentAll = 0;
  const totalMins = 1440;
  for (let y = 0; y<arr.length; y++) {
    const act = arr[y].activities;
    const total = act.length;

    for (let i = 0; i<act.length; i++) {
      if (act[i].taxonomy === taxonomy) {
        if (i + 1 < total) {
          current += calcMins(act[i].start, act[i + 1].start);
          currentAll += calcMins(act[i].start, act[i + 1].start);
        } else {
          current += totalMins - getMins(act[i].start);
          currentAll += totalMins - getMins(act[i].start);
        }
      } else {
        if (i + 1 < total) {
          currentAll += calcMins(act[i].start, act[i + 1].start);
        } else {
          currentAll += totalMins - getMins(act[i].start);
        }
      }
    }
  }

  const percentage = current / currentAll * 100;
  return Math.round(percentage * 10) / 10;
};

export function calcTimeMin(arr, taxonomy) {
  let current = 2000;
  const totalMins = 1440;

  for (let y = 0; y <arr.length; y++) {
    const act = arr[y].activities;
    const total = act.length;
    for (let i = 0; i< act.length; i++) {
      if (act[i].taxonomy === taxonomy) {
        if (i + 1 < total) {
          if (current > calcMins(act[i].start, act[i + 1].start)) {
            current = calcMins(act[i].start, act[i + 1].start);
          }
        } else {
          if (current > totalMins - getMins(act[i].start)) {
            current = totalMins - getMins(act[i].start);
          }
        }
      }
    }
  }

  return current;
};

export function calcTimeAvarage(arr, taxonomy) {
  let current = 0;
  const totalMins = 1440;
  let hits = 0;
  for (let y = 0; y <arr.length; y++) {
    const act = arr[y].activities;
    const total = act.length;
    for (let i = 0; i< act.length; i++) {
      if (act[i].taxonomy === taxonomy) {
        hits++;
        if (i + 1 < total) {
          current += calcMins(act[i].start, act[i + 1].start);
        } else {
          current += totalMins - getMins(act[i].start);
        }
      }
    }
  }

  const avarage = current/hits;
  return Math.round(avarage * 10) / 10;
};

export function calcNumberOfDays(arr, taxonomy) {
  let numberOfDays = 0;
  for (let y = 0; y< arr.length; y++) {
    const act = arr[y].activities;
    for (let i = 0; i<act.length; i++) {
      if (act[i].taxonomy === taxonomy) {
        numberOfDays++;
        break;
      }
    }
  }
  return numberOfDays;
};

export function calcDaysPercentage(arr, taxonomy) {
  let numberOfDays = 0;
  const totalNumberOfDays = arr.length;
  for (let y = 0; y< arr.length; y++) {
    const act = arr[y].activities;
    for (let i = 0; i<act.length; i++) {
      if (act[i].taxonomy === taxonomy) {
        numberOfDays++;
        break;
      }
    }
  }
  const percentage = numberOfDays / totalNumberOfDays * 100;
  return percentage;
};



/* eslint-disable */

const startIcon = require('../assets/menu-start-inactive.svg');
const startIconActive = require('../assets/menu-start-icon.svg');
const activitiesIcon = require('../assets/menu-activities-icon.svg');
const activitiesIconActive = require('../assets/menu-ticket.svg');
const taxonomiesIcon = require('../assets/menu-taxonomies-icon.svg');
const taxonomiesActive = require('../assets/menu-taxonomi-active.svg');
const languageIcon = require('../assets/menu-language-icon.svg');
const languageIconActive = require('../assets/menu-language-active.svg');


const menuItems = [
  {
    id: 1,
    name: 'Start',
    icon: startIcon,
    iconActive: startIconActive,
    link: '/',
  },
  {
    id: 2,
    name: 'Aktivitetsmönster',
    icon: activitiesIcon,
    iconActive: activitiesIconActive,
    link: '/aktiviteter',
  },
  {
    id: 3,
    name: 'Hjälp',
    icon: activitiesIcon,
    iconActive: activitiesIconActive,
    link: '/help',
  },
  {
    id: 4,
    name: 'Om oss',
    icon: activitiesIcon,
    iconActive: activitiesIconActive,
    link: '/about',
  },
  {
    id: 5,
    name: 'Vardagboken (App)',
    icon: activitiesIcon,
    iconActive: activitiesIconActive,
    link: 'https://vardagboken.everydayanalytics.se',
  },
];

export const menuItemsEN = [
  {
    id: 1,
    name: 'Start',
    icon: startIcon,
    iconActive: startIconActive,
    link: '/',
  },
  {
    id: 2,
    name: 'Activitypattern',
    icon: activitiesIcon,
    iconActive: activitiesIconActive,
    link: '/aktiviteter',
  },
  {
    id: 3,
    name: 'Help',
    icon: activitiesIcon,
    iconActive: activitiesIconActive,
    link: '/help',
  },
  {
    id: 4,
    name: 'About us',
    icon: activitiesIcon,
    iconActive: activitiesIconActive,
    link: '/about',
  },
  {
    id: 5,
    name: 'Vardagboken (App)',
    icon: activitiesIcon,
    iconActive: activitiesIconActive,
    link: 'https://vardagboken.everydayanalytics.se',
  },
];


export default menuItems;

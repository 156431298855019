/* eslint-disable */
import {FC} from 'react';
import {useRecoilValue} from 'recoil';
import {langState} from '../../state/activities';
import {default as text} from '../../translate/lang.json'
import { getMins, calcMins } from '../../utils/statisticsCounter';
import { tickArray, formatYAxis } from "../../utils/yAxisTimeStamps";
import { enPlaces } from '../../utils/categorylang';
import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    Legend,
  } from 'recharts';
import { moveMessagePortToContext } from 'worker_threads';
  interface Props {
    arr: any;
    showYAxis?: boolean;
  }
const PlacesGraph:FC <Props> = ({arr, showYAxis = true}) => {
   let newArr = arr.places;
   let totalMins = 1440;
   const lang = useRecoilValue(langState)

   function calcNumberOfLines(places: Array<any>, movments: Array<any>){
    let numberOfLines:number = 0;
    let lineObjects:Array<any> = [];
    
    for(let i = 1; i<places.length; i++){

      if(movments[i-1].category === "Förflyttning" && places[i].where !== places[i-1].where){
        numberOfLines++
        let startMins = getMins(places[i-1].start);
        let endMins = getMins(places[i].start);
        lineObjects.push({
          startMins: startMins,
          endMins: endMins,
          start: places[i-1].where,
          end: places[i].where
        })
      }
      else if(places[i].where !== places[i-1].where){
        numberOfLines++
        let startMins = getMins(places[i-1].start);
        let endMins = getMins(places[i].start);
        lineObjects.push({
          startMins: endMins,
          endMins: endMins,
          start: places[i-1].where,
          end: places[i].where
        })
      }
    }
    return lineObjects;
  }
  // function barLineMatch(arr: Array<any>){
  //   for(let i = 0; i<arr.length; i++){
  //     if(arr[i].startMins)
  //   }
  // }

  function lineString(index:number){

    return "line_"+index;
  }
  const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;
  
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={3} fill="black" viewBox="0 0 1024 1024">
        
      </svg>
    );
  };

   function createJson(arr: Array<any>, cat: string, startId: number, lineArr: Array<any>) {
     let jsonObject = {};
     let total = arr.length;
     let currentMin = 0;
     for (let i = 0; i < arr.length; i++) {
       if (i + 1 < total) {
         currentMin = calcMins(arr[i].start, arr[i + 1].start);
       } else {
         currentMin = totalMins - getMins(arr[i].start);
       }
       if(arr[i].category !=="Förflyttning"){
        Object.assign(jsonObject, {[i + startId]: currentMin});
       }
       
     }
     for(let y = 0; y < lineArr.length; y++){
      if(lineArr[y].start === cat){
        Object.assign(jsonObject, {['line_'+ (y)]: lineArr[y].startMins});
      }
      if(lineArr[y].end === cat){
        Object.assign(jsonObject, {['line_'+ (y)]: lineArr[y].endMins});
      }
    }
     return jsonObject;
   }
   function createStackedBar(arr: Array<any>, cat: Array<string>, lineArr: Array<any>): Array<any>  {
     let tmpArry: Array<any> = [];
     let length = 0;
     for (let i = 0; i < cat.length; i++) {
       let jsonObject = createJson(arr, cat[i], length, lineArr);
       tmpArry.push({
         name: cat[i],
         name_EN: enPlaces(cat[i]),
         ...jsonObject,
       });
       length += arr.length;
     }
     return tmpArry;
   }
   //function checkLineAndStack()

   function nextWhereMatch(act1, act2){
    if(act1.where === act2.where){
      return true
    }
    else{
      return false
    }
   }
 
   function createColorArray(arr: Array<any>, cat: Array<string>, act: Array<any>) {
     let colorArr:Array<boolean> = [];
 
     for (let i = 0; i < cat.length; i++) {
        let arrTot = arr.length
       for (let y = 0; y < arr.length; y++) {
         if (cat[i] === arr[y].where) {
          if(act[y].category !=="Förflyttning"){
            colorArr.push(true);
          }
          else if (i + 1 < arrTot) {
            colorArr.push(nextWhereMatch(arr[y], arr[y+1]));
          }
          else{
            colorArr.push(false)
          }
           
         } else {
           colorArr.push(false);
         }
       }
     }
     return colorArr;
   }
   let categories = [
     'Hemma',
     'Utomhus',
     'Arbete',
     'Skola',
     'Annat'
   ]
   let lineArr = calcNumberOfLines(arr.places, arr.activities);
   let dataArr = createStackedBar(newArr, categories, lineArr);
   let colors = createColorArray(newArr, categories, arr.activities);

    return(
       <>
       <h1 className='text-center'>{text.label_movment[lang]}</h1>
       <ResponsiveContainer
       height={550}
       width="100%"
       >
        <ComposedChart

        data={dataArr}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
         <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            angle={-20}
            dataKey={lang ==="EN"? 'name_EN' :'name'}
            interval={0}
            style={{
              borderTop: 15,
              borderColor: "black",
              fontSize: '0.6rem',
              fontWeight: 900,
            }}
          />
          {showYAxis && (
            <YAxis
              type="number"
              dataKey="x"
              ticks={tickArray}
              tickFormatter={formatYAxis}
              domain={[0, totalMins]}
              allowDataOverflow={true}
            />
          )}
        {colors.map((value, index) => (
            <Bar
              dataKey={index}
              stackId={'a'}
              barSize={10}
              fill={value ? '#000000' : 'transparent'}
              legendType='none'
            ></Bar>
          ))}
        {lineArr.map((val, index) => (
            <Line
              dataKey={lineString(index)}
              stroke="#000000"
              legendType='none'
              connectNulls
              strokeWidth={1}
              dot={<CustomizedDot/>}
            ></Line>
          ))}
      </ComposedChart>
      </ResponsiveContainer>
      </>
    )
}

export default PlacesGraph